//import type { View } from '@playful/playkit/playkit';

import type { Component } from './component';
import type { IProject, IProjectModel, IProjectStore } from './designRuntime';
import type { InspectorDescription } from './designRuntimeInspectors';
import type { Interaction } from './interactions';
import type { PropertyEditorProps } from '.';

// Pre-defined property types.
export type PropertyType =
  | 'boolean'
  | 'string'
  | 'number'
  | 'date'
  | 'array'
  | 'rectangle'
  | 'object';

export type FunctionComponent<T = any> = React.FC<T>;
export type ComponentType<T = any> = React.ComponentType<T>;
export type DeepPartial<T> = T extends Record<string, unknown>
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

// TODO:
export enum Designer {}

export type DesignerProps = {
  view: Component;
};

export type RootDesignerProps = {
  projectModel: IProjectModel;
  projectStore: IProjectStore;
  project: IProject;
  viewportRect: DOMRect;
} & DesignerProps;

/**
 * KitDescription describes a Kit (set) of Play Components. Kit Descriptions may be
 * defined in code or in .json files so all types must be JSON compatbile.
 */
export type KitDescription = {
  // Human readable title of the kit.
  title?: string;

  // Human readable description of the kit. TODO: markdown?
  description?: string;

  author?: string; // TODO: userId or unique username?

  // The list of components in the kit.
  components?: ComponentDescription[];
};

/**
 * ComponentMetaData describes the core interaction points contained both
 * in ComponentDescriptions and within component instances themselves.
 */
export type ComponentMetaData = {
  // Human readable title of the component.
  title?: string;

  // Human readable description of the component. TODO: markdown?
  description?: string;

  // Markdown documentation for the component. This is used to genereate
  // component documentation in docusaurus. Currently, the docusaurus docs
  // reuse description. Now documentation will supercede description. TODO is
  // that the right choice? Should they both be visibile if specified? For now
  // I went with replacement because the user work around is to copy the
  // description to the top of the documentation, but going the other way would
  // not have that easy use workaround in reverse.
  documentation?: string;

  // Determines whether a component shows up in the components list
  exported?: boolean;

  // Whether this is an Effect or a Component.
  isEffect?: boolean;

  // If set to false, a generated preview will be used
  customPreview?: boolean;

  author?: string; // TODO: userId or unique username?
  icon?: string;
  preview?: string;
  collection?: string; // TODO: necessary?

  // The set of properties this component has, keyed by property name.
  properties?: { [property: string]: PropertyDescription };

  // The set of events this component can fire, keyed by event name.
  events?: { [event: string]: EventDescription };

  commands?: { [command: string]: CommandDescription };

  actions?: { [action: string]: ActionDescription };

  // Tells the designer that this component supports going into `componentDesignMode` which allows
  // the component to be interacted with directly in the designer when a user doubleClicks/taps on a component.
  supportComponentDesignMode?: boolean;

  inspectors?: InspectorDescription[];

  mobileInspectors?: InspectorDescription[];

  linkable?: boolean;
};

/**
 * ComponentDescription describes a Play Component. Everything users, the design time environment,
 * and the runtime environment need to be able to make use of the component. ComponentDescriptions
 * may be defined in code or in .json files so all types must be JSON compatbile.
 */
export type ComponentDescription = {
  // Computer readable name of the component.
  name: string;
  interactions?: Interaction[];

  // Component metadata
  _meta: PartialComponentMetaData;

  // A component that this component extends (inherits ComponentDescription and prototype from).
  extends?: string;

  // Reference to a React component that renders this component.
  renderer?: React.FC<any>; // TODO: React.ComponentType?

  // Reference to the component that provides the Designer UI for this component.
  // It may be a pre-defined Designer or a React Component conforming to the ReactDesigner interface.
  designer?: ComponentType<DesignerProps>;

  // Reference to the component's Javascript prototype.
  prototype?: any;
};

// ComponentMetaData, but with with everything optional.
export type PartialComponentMetaData = DeepPartial<ComponentMetaData>;

/**
 * PropertyDescription describes a property of a Play Component. Everything users,
 * the design time environment, and the runtime environment need to make use of the
 * property. PropertyDescriptions may be defined in code or in .json files so all
 * types must be JSON compatbile.
 */
export type PropertyDescription = {
  /**
   * One of the pre-defined property types.
   * The PropertyType describes the kind of data the property tracks.
   */
  type: PropertyType;

  // Human readable title of the property.
  title?: string;

  // base64 dataURL
  icon?: string;

  // Human readable description of the property. TODO: markdown?
  description?: string;

  // The default value of the property.
  default?: any;

  // Is this property visible in the Inspector UI?
  hidden?: boolean;

  // Is this property visible on custom component instances?
  private?: boolean;

  // This property should not be edited.
  readonly?: boolean;

  // This property should not force re-rendering.
  noRender?: boolean;

  // Reference to the property editor the Inspector should display for this property.
  // May reference a pre-defined property editor or a custom React component conforming
  // to the ReactPropertyEditor interface.
  editor?: PropertyEditorProps;

  // When true this is the component's "primary" property used when a default is needed.
  primary?: boolean;
};

/**
 * EventDescription describes an event that a Play Component can fire. Everything users,
 * the design time environment, and the runtime environment need to establish event
 * handlers. EventDescriptions may be defined in code or in .json files so all types
 * must be JSON compatible.
 */
export type EventDescription = {
  // Human readable title of the property.
  title?: string;

  // Human readable description of the property. TODO: markdown?
  description?: string;

  // Is this event visible in the Inspector UI?
  hidden?: boolean;

  // When true this is the component's "primary" event used when a default is needed.
  primary?: boolean;
};

export type CommandDescription = {
  // Human readable title of the command.
  title?: string;

  // Human readable description of the command.
  description?: string;

  // Is this a command that wants to participate in the undo stack during design time?
  undoable?: boolean;
};

export type ActionDescription = {
  // Human readable title of the action.
  title?: string;

  // Human readable description of the action.
  description?: string;

  template?: string;

  parameters?: {
    [parameter: string]: ParameterDescription;
  };

  returnValue?: ParameterDescription;

  // When true this is the component's "primary" action used when a default is needed.
  primary?: boolean;
};

export type ParameterType =
  | 'any'
  | 'boolean'
  | 'string'
  | 'number'
  | 'date'
  | 'array'
  | 'object'
  | 'color'
  | 'property'
  | 'property-value'
  | 'animation'
  | 'longstring'
  | 'actions'
  | 'formula';

export type ParameterDescription = {
  // Human readable title of the parameter.
  title?: string;

  // Human readable description of the parameter.
  description?: string;

  // Type of the parameter.
  type?: ParameterType;

  // Default value of the parameter.
  default?: any;

  // Is the parameter optional? (default: false)
  optional?: boolean;
};
