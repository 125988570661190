export const menuTheme = {
  defaultProps: {},
  parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
  baseStyle: {
    button: { transitionProperty: 'common', transitionDuration: 'normal' },
    list: {
      bg: 'white',
      boxShadow: 'sm',
      color: 'inherit',
      minW: '3xs',
      py: 2,
      zIndex: 1,
      borderRadius: 'md',
      border: '0.0625rem solid',
      borderColor: 'gray.100',
    },
    item: {
      py: 2,
      px: 3,
      transitionProperty: 'background',
      transitionDuration: 'ultra-fast',
      transitionTimingFunction: 'ease-in',
      _focus: { bg: 'gray.50' },
      _active: { bg: 'gray.100' },
      _expanded: { bg: 'gray.50' },
      _disabled: { opacity: 0.4, cursor: 'not-allowed' },
    },
    groupTitle: { mx: 4, my: 2, fontWeight: 700, fontSize: 'sm' },
    command: { opacity: 0.6 },
    divider: {
      border: 0,
      borderBottom: '0.0625rem solid',
      borderColor: 'gray.100',
      my: 2,
      opacity: 0.6,
    },
  },
  variants: {},
  sizes: {},
};
