export const numberInputTheme = {
  defaultProps: { size: 'md', variant: 'outline' },
  parts: ['root', 'field', 'stepperGroup', 'stepper'],
  baseStyle: {
    root: {
      '--number-input-stepper-width': '24px',
      '--number-input-input-padding': 'calc(var(--number-input-stepper-width) + 0.5rem)',
    },
    field: {
      width: '100%',
      minWidth: 0,
      outline: 0,
      position: 'relative',
      appearance: 'none',
      transitionProperty: 'common',
      transitionDuration: 'normal',
    },
    stepperGroup: { width: ['var(--number-input-stepper-width)'] },
    stepper: {
      borderStart: '1px solid',
      borderStartColor: 'inherit',
      color: 'inherit',
      _active: { bg: 'gray.200' },
      _disabled: { opacity: 0.4, cursor: 'not-allowed' },
    },
  },
  variants: {
    outline: {
      field: {
        border: '1px solid',
        borderColor: 'inherit',
        bg: 'inherit',
        _hover: { borderColor: 'gray.300' },
        _readOnly: { boxShadow: 'none !important', userSelect: 'all' },
        _disabled: { opacity: 0.4, cursor: 'not-allowed' },
        _invalid: {
          borderColor: 'red.500',
        },
        _focus: {
          zIndex: 1,
          outline: '0.125rem solid',
          outlineColor: 'primary.500',
          outlineOffset: '-0.125rem',
        },
      },
      addon: { border: '1px solid', borderColor: 'inherit', bg: 'gray.100' },
    },
    unstyled: {
      field: { bg: 'transparent', px: 0, height: 'auto' },
      addon: { bg: 'transparent', px: 0, height: 'auto' },
    },
  },
  sizes: {
    xs: {
      field: {
        fontSize: 'xs',
        px: 2,
        h: 6,
        borderRadius: 'sm',
        paddingInlineEnd: 'var(--number-input-input-padding)',
        verticalAlign: 'top',
      },
      stepper: {
        fontSize: 'calc(0.75rem * 0.75)',
        _first: { borderTopEndRadius: 'sm' },
        _last: { borderBottomEndRadius: 'sm', mt: '-1px', borderTopWidth: 1 },
      },
    },
    sm: {
      field: {
        fontSize: 'sm',
        px: 3,
        h: 8,
        borderRadius: 'sm',
        paddingInlineEnd: 'var(--number-input-input-padding)',
        verticalAlign: 'top',
      },
      stepper: {
        fontSize: 'calc(0.875rem * 0.75)',
        _first: { borderTopEndRadius: 'sm' },
        _last: { borderBottomEndRadius: 'sm', mt: '-1px', borderTopWidth: 1 },
      },
    },
    md: {
      field: {
        fontSize: 'md',
        px: 4,
        h: 10,
        borderRadius: 'md',
        paddingInlineEnd: 'var(--number-input-input-padding)',
        verticalAlign: 'top',
      },
      stepper: {
        fontSize: 'calc(1rem * 0.75)',
        _first: { borderTopEndRadius: 'md' },
        _last: { borderBottomEndRadius: 'md', mt: '-1px', borderTopWidth: 1 },
      },
    },
    lg: {
      field: {
        fontSize: 'lg',
        px: 4,
        h: 12,
        borderRadius: 'md',
        paddingInlineEnd: 'var(--number-input-input-padding)',
        verticalAlign: 'top',
      },
      stepper: {
        fontSize: 'calc(1.125rem * 0.75)',
        _first: { borderTopEndRadius: 'md' },
        _last: { borderBottomEndRadius: 'md', mt: '-1px', borderTopWidth: 1 },
      },
    },
  },
};
