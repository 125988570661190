import type { AxiosRequestConfig } from 'axios';

import { getFirebaseAuthToken } from './firebase';

export async function mergeTokenConfig(config: AxiosRequestConfig & RequestInit) {
  const token = await getFirebaseAuthToken();
  const headerAuth = token ? { Authorization: `Bearer ${token}` } : undefined;

  return {
    ...config,
    headers: {
      ...config.headers,
      ...headerAuth,
    },
  };
}
