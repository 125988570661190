// Exponential delay.. Should be 1ms,10ms,100ms,1s,10s,
const delay = (retryCount: number) =>
  new Promise((resolve) => setTimeout(resolve, 10 ** retryCount));

export const fetchRetry = async (
  apiRequest: () => Promise<any>,
  numberOfTries: number,
  retryCount = 0,
  lastError?: any,
): Promise<any> => {
  if (retryCount > numberOfTries) throw new Error(lastError);
  try {
    const res = await apiRequest();
    // Any request that returns a response, even if that response is 404
    // will not throw an error. However, the response.ok will be false.
    // We want to retry when response.ok is false AND when an exception is thrown.
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res;
  } catch (e) {
    await delay(retryCount);
    return fetchRetry(apiRequest, numberOfTries, retryCount + 1, e);
  }
};
