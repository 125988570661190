// Reactor
// - encapsulates state and exposes as expression ("$" prefixed) and value properties
// - evaluates expression properties to produce value properties
// - tracks dirty state (invalidate, validate, update)
// - property changes can be observed

//import { createReactorArray } from './reactorArray';
//import { createReactorObject } from './reactorObject';
import { isObjectNotArray, isObjectOrArray } from './util';

export type ReactorId = number;
export const ID = Symbol('ID');

export type ChangeType = 'add' | 'remove' | 'change';
export type ChangeListener = (
  reactor: Reactor,
  property: PropertyKey,
  newValue: any,
  oldValue: any,
  type: ChangeType,
) => void;

export type UpdateListener = (reactor: Reactor, changed: Properties) => void;

export type Properties = {
  [property: string]: any;
  [property: symbol]: any;
};

export interface IDisposable {
  dispose(): void;
}

// Shared by ReactorObject and ReactorArray // TODO: -> Reactor?
export interface ReactorBase extends IDisposable {
  readonly [ID]: ReactorId;

  onPropertyChange(listener: ChangeListener): IDisposable;
}

export type ReactorArrayBase = ReactorBase;

export interface ReactorArray<T = any> extends ReactorArrayBase, Array<T> {
  [property: number]: T;
}

// Public interface
export interface ReactorObjectBase extends ReactorBase {
  validate(): void;
  invalidate(property?: string): void;
  update?(changed: Properties): void;
  clearDirty(): void;
  getState(includeDefaultValues?: boolean): Properties;

  //getOwnProperty(property: PropertyKey): any | undefined;
  //getProperty(property: PropertyKey): any | undefined;
  getOwnStoredProperties(): Properties;
  getStoredProperties(): Properties;

  onUpdate(listener: UpdateListener): IDisposable;

  // TODO: hack to make TS happy. Need to add setReactorStoredProperty, etc here
  [property: symbol]: any;
}

export interface ReactorObject extends ReactorObjectBase {
  [property: string]: any;
  [property: symbol]: any;
}

export interface Reactor extends ReactorBase {
  [property: number]: any;
  [property: string]: any;
  [property: symbol]: any;
}

export function isReactor(object: any): object is Reactor {
  if (!object) {
    return false;
  }
  return object._isReactor === true;
}

export function parseComponentType(qualifiedType: string): {
  importPath?: string;
  unqualifiedType: string;
} {
  if (qualifiedType.indexOf('/') === -1) {
    return { unqualifiedType: qualifiedType };
  } else {
    const s = qualifiedType.split('/');
    const unqualifiedType = s.pop()!;
    const importPath = s.join('/');
    return { importPath, unqualifiedType };
  }
}

export function qualifyComponentType(
  importPath: string | undefined,
  unqualifiedType: string,
): string {
  return importPath !== undefined ? importPath + '/' + unqualifiedType : unqualifiedType;
}

// Remove ids from an object hierarchy or array in place.
export function removeIds<T>(state: T): T {
  delete (state as any)[ID];

  // Recurse on child objects and arrays;
  if (Array.isArray(state)) {
    for (const value of state) {
      if (isObjectOrArray(value)) {
        removeIds(value);
      }
    }
  } else {
    for (const key in state) {
      const value = state[key];
      if (isObjectOrArray(value)) {
        removeIds(value);
      }
    }
  }
  return state;
}

// TODO: dates, regex
export function serialize(state: any): string {
  return JSON.stringify(
    // create a copy of the state before serializing to avoid mutating the original
    state,
    (_key: string, value: any): any => {
      // ReactorArray ids are encoded as an extra element at the end of the array.
      if (Array.isArray(value) && (value as ReactorArray)[ID]) {
        const valuePlusId = value.slice();
        valuePlusId.push(`__id:${(value as ReactorArray)[ID]}`);
        return valuePlusId;
      } else if (isObjectNotArray(value) && (value as ReactorObject)[ID]) {
        return { ...value, id: (value as ReactorObject)[ID] };
      } else {
        return value;
      }
    },
    2,
  );
}

export function transformIdToSymbol(value: any) {
  if (Array.isArray(value) && value.length > 0) {
    // ReactorArray ids are encoded as an extra element at the end of the array.
    const idString = value[value.length - 1];
    if (typeof idString === 'string') {
      if (idString.startsWith('__id:')) {
        (value as any)[ID] = parseInt(idString.split(':')[1]);
        value.pop();
      }
    }
  } else if (isObjectNotArray(value) && value.id !== undefined) {
    // Translate old id values to new symbol value
    (value as any)[ID] = value.id;
    delete value.id;
  }

  return value;
}

// TODO: dates, regex
export function deserialize(s: string): any {
  return JSON.parse(s, (_key: string, value: any): any => {
    return transformIdToSymbol(value);
  });
}
