import type { ChangeType, Component } from '@playful/runtime';
import { useEffect, useState } from 'react';

import type { IDisposable, Reactor } from '../reactor';
import { useImmutableDependencies } from './useImmutableDependencies';

export function useOnPropertyChange(
  component: Component,
  dependencies?: PropertyKey[],
): { property?: PropertyKey; newValue?: any; oldValue?: any; type?: ChangeType } {
  const [change, setChange] = useState<{
    property: PropertyKey;
    newValue: any;
    oldValue: any;
    type: ChangeType;
  }>();

  const immutableDependencies = useImmutableDependencies(dependencies);

  useEffect(() => {
    if (!component) {
      // Shouldn't be required, but is a nice sanity check.
      return;
    }

    function handleUpdate(
      reactor: Reactor,
      property: PropertyKey,
      newValue: any,
      oldValue: any,
      type: ChangeType,
    ): void {
      // If none of the changes properties are in the dependency array, don't update
      if (immutableDependencies.length > 0 && !immutableDependencies.includes(property)) {
        return;
      }

      setChange({ property, newValue, oldValue, type });
    }
    const listener: IDisposable | undefined = component?.onPropertyChange?.(handleUpdate);

    return () => {
      listener.dispose();
    };
  }, [component, immutableDependencies]);

  if (component && change) {
    return change;
  }
  return { property: undefined, newValue: undefined, oldValue: undefined, type: undefined };
}
