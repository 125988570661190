export const MIGRATOR_DEBUG = false;

const PREFIX = 'Migrator: ';

export const debug: typeof console.debug = (...args) =>
  MIGRATOR_DEBUG && console.debug(PREFIX, ...args);

export const error: typeof console.error = (...args) =>
  MIGRATOR_DEBUG && console.error(PREFIX, ...args);

export const group: typeof console.group = (...args) =>
  MIGRATOR_DEBUG && console.group(PREFIX, ...args);

export const groupCollapsed: typeof console.groupCollapsed = (...args) =>
  MIGRATOR_DEBUG && console.groupCollapsed(PREFIX, ...args);

export const groupEnd: typeof console.groupEnd = () => MIGRATOR_DEBUG && console.groupEnd();

export const info: typeof console.info = (...args) =>
  MIGRATOR_DEBUG && console.info(PREFIX, ...args);

export const log: typeof console.log = (...args) => MIGRATOR_DEBUG && console.log(PREFIX, ...args);

export const trace: typeof console.trace = (...args) =>
  MIGRATOR_DEBUG && console.trace(PREFIX, ...args);

export const warn: typeof console.warn = (...args) =>
  MIGRATOR_DEBUG && console.warn(PREFIX, ...args);
