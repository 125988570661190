import { ID } from './reactor';
import type { Properties, Reactor, ReactorId, ReactorObject } from './reactor';
import { createReactorArray } from './reactorArray';
import { createReactorObject } from './reactorObject';

export type CreateReactorOptions = {
  noId?: boolean;
};

export function createReactorFactory(nextReactorId = 1): ReactorFactory {
  return new ReactorFactory(nextReactorId);
}

export class ReactorFactory {
  /** @internal */
  _nextReactorId: number;

  /** @internal */
  _reactors: { [id: number]: Reactor | undefined } = {
    0: { [ID]: -1, name: 'invalid Reactor' } as any,
  };

  /** @internal */
  rootReactor!: ReactorObject;

  constructor(nextReactorId = 1) {
    this._nextReactorId = nextReactorId;
  }

  // TODO: dispose()?

  reactorCount(): number {
    // account for the 0th/-1th reactor
    return Object.keys(this._reactors).length - 1;
  }

  getNextReactorId(): ReactorId {
    return this._nextReactorId;
  }

  getReactorById(id: ReactorId): Reactor {
    const reactor = this._reactors[id];
    if (!reactor) {
      throw new Error(`reactor id ${id} isn't in reactor index`);
    }
    return reactor;
  }

  hasReactor(id: ReactorId): boolean {
    return this._reactors[id] !== undefined;
  }

  // Reactor paths are dot-separated property names, e.g. Components.View.5.whatever
  getReactorByPath(path: string): Reactor | undefined {
    const segments = path.split('.');
    let reactor = this.rootReactor;
    if (path === '') {
      return reactor;
    }
    for (const segment of segments) {
      const r = reactor[segment];
      if (r === undefined) {
        return undefined; // Path not found
      }
      reactor = r;
    }
    return reactor;
  }

  forEachReactor(callback: (reactor: Reactor) => boolean): void {
    for (const id in this._reactors) {
      const reactor = this._reactors[id];
      if (reactor && reactor[ID] !== -1) {
        if (!callback(reactor)) {
          // Abort enumeration when callback returns false.
          return;
        }
      }
    }
  }

  //
  //
  //

  createReactor<T = Reactor>(objectOrArray?: Properties | [], options?: CreateReactorOptions): T {
    if (Array.isArray(objectOrArray)) {
      return createReactorArray(this, objectOrArray, options) as unknown as T;
    } else {
      return createReactorObject(this, objectOrArray, options) as unknown as T;
    }
  }

  // Used by ReactorObject and ReactorArray to add a new reactor with an optional id
  addReactor(reactor: Reactor, id?: ReactorId): ReactorId {
    if (id) {
      // Be sure generated ids don't overlap existing ids.
      if (id >= this._nextReactorId) {
        this._nextReactorId = id + 1;
      }
    } else {
      id = this._nextReactorId++;
    }

    console.assert(
      this._reactors[id] === undefined,
      `Reactor id ${id} already in use!\n`,
      this._reactors[id],
    );
    this._reactors[id] = reactor;
    return id;
  }

  removeReactor(id: ReactorId) {
    if (id <= 0) {
      console.warn(`Invalid reactor id: ${id}`);
      return;
    }
    delete this._reactors[id];
  }
}
