import type { ChangeEvent, FocusEvent } from 'react';

import type { Component } from './component';
import type { ProjectAppliedMigrations } from './migrator/types';
import type { Properties, Reactor, ReactorId, ReactorObjectBase } from './reactor';
import type { CreateReactorOptions } from './reactorFactory';

export type Point = { x: number; y: number };

export type Rectangle = { x: number; y: number; w: number; h: number };

export type Corners = { tl: Point; tr: Point; bl: Point; br: Point };

// Of the format "#rrggbb" or "#rrggbbaa"
export type Color = string;

export function isOnlyPage(reactor: Reactor): boolean {
  const pages = reactor.project?.pages as Reactor[];
  if (!pages) {
    return false;
  }
  return pages.length === 1 && pages[0] === reactor;
}

export type Tags = { [tag: string]: true | null };

export type ProjectPermissions = {
  allowRemixing: boolean;
  liveUpdate: boolean;
  locked: boolean;
  showInGallery: boolean;
  showLogo: boolean;
};

// TODO: how to share this type with server?
export type ProjectInfo = {
  id: ProjectId; // Unique across all projects
  owner: UserId; // User id of the project's owner
  ownerName: string; // Username of the project's owner
  title: string;
  template: ProjectId; // Project id of the template this project is derived from
  created: number; // Timestamp (e.g. Date.now())
  modified: number; // Timestamp (e.g. Date.now())
  project: ResourceId; // ResourceId of the project data
  version: number; // Incremented at save time
  publishedProject?: ResourceId; // ResourceId of the published project data
  publishedVersion?: number;
  published?: number; // A timestamp, like created, modified.
  slug: string;
  // Reserved tags: _template, _kit, _internal
  tags?: Tags;
  permissions: ProjectPermissions;
};

export type ImportInfo = {
  // Stored properties.
  name: string;
  // <"playkit" | "project">:<path>
  source: string;
  projectVersion?: number;
  projectId?: string;
  importedComponents?: string[];

  loader?: () => Promise<any>;

  // Runtime properties.
  hasUpdate?: boolean;
};

export type ProjectId = string;
export type ResourceId = string;
export type UserId = string;

export interface Workbench {}

export type ProjectState = {
  runtimeVersion?: number; // TODO: keep optional. This is going away as soon as we build a batch project migrator
  appliedMigrations?: ProjectAppliedMigrations;
  Imports?: ImportInfo[];
  Components?: { [type: string]: Component };
  pages?: any[]; // This is actually Page[];
  style?: Component;
};

// TODO: Eliminate frontend dependencies on Page
export type Page = Component & { width: number; height: number };

export interface Project extends Component {
  viewportX: number;
  viewportY: number;
  viewportWidth: number;
  viewportHeight: number;

  mainProject: Project;
  designMode: boolean;
  thumbnailMode: boolean;
  resourceRoot: string; // root path for all resource loading
  readonly info: ProjectInfo;

  Imports: ImportInfo[];
  imports: Record<string, any>; //TODO: Improve this type. It should be either a 'kit' or a project
  Components: { [type: string]: Component } & ReactorObjectBase;
  pages: Page[];

  createReactor<T extends Reactor>(properties?: Properties, options?: CreateReactorOptions): T;
  createComponent<T extends Component>(properties: Properties): T;
  getReactorById(id: ReactorId): Reactor;
  getComponentById(id: ReactorId): Component;
  hasReactor(id: ReactorId): boolean;
  forEachReactor(callback: (reactor: Reactor) => boolean): void;
  dispose(): void;

  // TODO: move these to a View object?
  mount(container: HTMLElement | ShadowRoot): void;
  unmount(): void;
  toCanvas(context: CanvasRenderingContext2D): Promise<void>;
}

export type ResourceSource = string; // Access string in the format of `resource:<resourceKey>`

export interface IResourceJson {
  id: string;
  secret: string;
  name: string;
  created: string;
  modified: string;
  keys: Array<ResourceData>;
}

export type ResourceUrlOptions = {
  transform?: string; // image transforms
  auth?: string; // auth token
  v?: string; //cache busting
  name?: string; // used to set the name when uploading
};

export interface IResource {
  readonly id: ResourceId;
  secret?: string;
  name?: string;
  keys: Array<ResourceData>;
  created: Date;
  modified: Date;
  getData(key: string): ResourceData | undefined;
  getDataUrl(key: string, options?: ResourceUrlOptions): string;
}

export interface ResourceData {
  key: string;
  mimeType?: string;
  size?: number;
  hash?: string;
  name?: string;
  created?: Date;
  url?: string; // Data URL, probably a blob:
  progress?: number; // Upload progress
}

// TODO is this the right place for these?
export interface FormValidationChange {
  name: string;
  value: any;
}

export type BlurHandlerEventType =
  | FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  | FormValidationChange;

export type ChangeHandlerEventType =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | FormValidationChange;

export type BlurHandlerType = (event: BlurHandlerEventType) => void;
export type ChangeHandlerType = (event: ChangeHandlerEventType) => void;
