import {
  Tab as ChTab,
  TabList as ChTabList,
  TabPanel as ChTabPanel,
  TabPanels as ChTabPanels,
  Tabs as ChTabs,
} from '@chakra-ui/tabs';
import React from 'react';

import type { TabListProps, TabPanelProps, TabPanelsProps, TabProps, TabsProps } from './types';

/**
 * Tabs component. See [Tabs](https://v1.chakra-ui.com/docs/components/disclosure/tabs) for the complete documentation.
 */
export const Tabs = (props: TabsProps) => {
  return <ChTabs {...props} />;
};

export const Tab = (props: TabProps) => {
  return <ChTab {...props} />;
};

export const TabList = (props: TabListProps) => {
  return <ChTabList {...props} />;
};

export const TabPanels = (props: TabPanelsProps) => {
  return <ChTabPanels {...props} />;
};

export const TabPanel = (props: TabPanelProps) => {
  return <ChTabPanel {...props} />;
};
