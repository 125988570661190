// Keep this synced with go/cmd/frontend/config.go
export type Config = {
  environment: string;
  gitBranch: string;
  baseUrl: string;
  apiRoot: string;
  sandboxConfig: {
    host: string;
    preserveLocalPort: boolean;
    redirectLocal?: string;
    sandboxRuntime: string;
  };
  googleProjectId: string;
  logRocketId: string;
  firebaseDatabaseUrl: string;
  firebaseAuthDomain: string;
  firebaseApiKey: string;
  firebaseAppId: string;
  firebaseSenderId: string;
  intercomApiUrl: string;
  intercomAppId: string;
  traceEndpoint?: string;
  honeycombKey?: string;
};

let cfg: Config | undefined;

export function getConfig() {
  if (!cfg) {
    throw new Error('Please call loadConfig first!');
  }
  return cfg;
}

export async function loadConfig(): Promise<Config> {
  // Try to load from script tag first
  const elem = document.getElementById('config');
  if (elem) {
    //console.log('Loading config from inline JSON');
    try {
      cfg = JSON.parse(elem.textContent!);
    } catch (err) {
      console.log('Error loading config JSON', err);
    }
  }

  // Nothing yet? Load from server
  if (!cfg) {
    //console.log('Loading config from server');

    // TODO: support a query param for specifying config url
    const res = await fetch('/_/config');
    cfg = (await res.json()) as Config;
  }
  return cfg;
}
