import type { Component, Properties } from '@playful/runtime';
import { META } from '@playful/runtime';
import { useEffect, useState } from 'react';

import type { IDisposable, Reactor } from '../reactor';
import { useImmutableDependencies } from './useImmutableDependencies';

function getComponentState(component: Component) {
  const props: Properties = {};
  Object.keys(component?.[META]?.properties || {}).forEach((property) => {
    if (property in component) {
      // copy the props.
      props[property] = component[property];
    }
  });
  return props;
}

/*
This hook subscribes a React component to the `update` cycle of a Component. It will trigger
an update immediately after the `update()` is called in a Component, and it will synchronously
return the state of the component as it is after the `update()` call.
An optional dependency array allows you to specify an array of propertyKeys to subscribe to.
If supplied, the hook will cause the React component to render only if one of the properties specified
is updated.

Only properties defined in the META are watched.

Examples:
Update when any defined property changes:
const state = useComponentState(component);
const state = useComponentState(component, []);

Update when the name property changes:
const state = useComponentState(component, ['name']);

 */
export function useOnComponentUpdate<T extends Properties>(
  component: Component,
  dependencies?: PropertyKey[],
): T {
  const [componentState, setComponentState] = useState(getComponentState(component as Component));

  const immutableDependencies = useImmutableDependencies(dependencies);

  useEffect(() => {
    if (!component) {
      // Shouldn't be required, but is a nice sanity check.
      return;
    }

    function handleUpdate(_component: Reactor, changed: Properties): void {
      // If none of the changes properties are in the dependency array, don't update
      if (
        immutableDependencies.length > 0 &&
        !Object.keys(changed).some((p) => immutableDependencies.includes(p))
      ) {
        return;
      }

      setComponentState(getComponentState(_component as Component));
    }
    const listener: IDisposable | undefined = component.onUpdate(handleUpdate);

    return () => {
      listener.dispose();
    };
  }, [component, immutableDependencies]);

  return componentState as T;
}
