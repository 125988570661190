export type ImageSizeInfo = {
  height: number;
  width: number;
  aspectRatio: number;
};

export function getImageSize(src: string): Promise<ImageSizeInfo | undefined> {
  return new Promise<ImageSizeInfo | undefined>((resolve, reject) => {
    const img = document.createElement('img');
    img.onerror = (event) => {
      console.warn('getImageSize.onerror', event);
      reject(undefined);
    };

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
        aspectRatio: img.naturalWidth / img.naturalHeight,
      });
    };

    img.src = src;
  });
}

export function getVideoSize(src: string): Promise<ImageSizeInfo | undefined> {
  return new Promise<ImageSizeInfo | undefined>((resolve, reject) => {
    const video = document.createElement('video');

    video.onerror = (event) => {
      console.warn('getVideoSize.onerror', event);
      reject(undefined);
    };

    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        aspectRatio: video.videoWidth / video.videoHeight,
      });
    };

    video.src = src;
  });
}
