import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const switchTheme = {
  defaultProps: { size: 'md', colorScheme: 'primary' },
  parts: ['container', 'track', 'thumb'],
  baseStyle: ({ colorScheme }: StyleFunctionProps) => ({
    container: {
      '--switch-track-diff': 'calc(var(--switch-track-width) - var(--switch-track-height))',
      '--switch-thumb-x': 'var(--switch-track-diff)',
      _rtl: { '--switch-thumb-x': 'calc(var(--switch-track-diff) * -1)' },
    },
    track: {
      borderRadius: 'full',
      p: '0.125rem',
      width: ['var(--switch-track-width)'],
      height: ['var(--switch-track-height)'],
      transitionProperty: 'common',
      transitionDuration: 'fast',
      bg: 'gray.200',
      _disabled: { opacity: 0.4, cursor: 'not-allowed' },
      _checked: { bg: `${colorScheme}.500` },
      _focus: {
        boxShadow: 'none',
      },
    },
    thumb: {
      bg: 'white',
      transitionProperty: 'transform',
      transitionDuration: 'normal',
      borderRadius: 'inherit',
      width: ['var(--switch-track-height)'],
      height: ['var(--switch-track-height)'],
      _checked: { transform: 'translateX(var(--switch-thumb-x))' },
    },
  }),
  variants: {},
  sizes: {
    sm: { container: { '--switch-track-width': '1.375rem', '--switch-track-height': '0.75rem' } },
    md: { container: { '--switch-track-width': '1.875rem', '--switch-track-height': '1rem' } },
    lg: { container: { '--switch-track-width': '2.875rem', '--switch-track-height': '1.5rem' } },
  },
};
