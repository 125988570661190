import { axiosRequest } from './axiosRequest';
import type { User } from './user';

type AuthenticateArguments = {
  userId: string;
  marketingOptIn?: boolean;
};

// Login/create a user
export async function authenticateUser({
  userId,
  marketingOptIn,
}: AuthenticateArguments): Promise<User> {
  const { data } = await axiosRequest('authenticate', {
    method: 'POST',
    withCredentials: true,
    data: { userId, marketingOptIn },
  });
  return data;
}
