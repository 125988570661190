import type { Component } from './component';
import type {
  IDisposable,
  Properties,
  Reactor,
  ReactorArray,
  ReactorId,
  ReactorObject,
} from './reactor';
import type {
  IResource,
  IResourceJson,
  Page,
  Point,
  Project,
  ProjectId,
  ResourceId,
} from './runtime';

export type ClipboardData = {
  source: Source;
  clipboard: ClipboardItem[];
};

export type Source = {
  projectId: ProjectId;
  projectResourceId: ResourceId | undefined;
};

export type ComponentImport = {
  componentType: string;
  projectId: ProjectId;
};

export type ComponentInfo = {
  resourceKeys: string[];
  imports: ComponentImport[];
};

export type ClipboardItem = ComponentInfo & {
  state: Properties;
  projectId: ProjectId | undefined;

  // Used by Kits to signal this item (a Page) should replace the current page, not add a  new one.
  replacePage?: boolean;
};

//TODO: copied from playkit/panZoom/panZoomProxyState.ts
type PanZoomTransform = { x: number; y: number; zoom: number };

export type AddContentOptions = {
  position?: Point;
  width?: number;
  height?: number;
  hierarchyPosition?: {
    parent: Reactor;
    propertyOrIndex: string | number;
  };
  componentId?: ReactorId;
  aspectRatio?: number;
  property?: string; // Defaults to "source" if not specified.
  fromKit?: boolean;
};

export type AddResourceOptions = AddContentOptions & {
  componentType?: string;
};

export type AddComponentOptions = AddContentOptions & {
  noSuggestPosition?: boolean;
};

export type Selection = Component[];

export type PropertySet = { component: Component; property: string; value: any };

export interface IProjectModel {
  setSelection(selection: Selection): void;
  getSelection(): Selection;
  onChangeSelection(listener: (event: { readonly selection: Selection }) => void): IDisposable;

  setDesignRoot(designRoot: Reactor | undefined): void;
  getDesignRoot(): Reactor | undefined;
  onChangeDesignRoot(
    listener: (event: {
      readonly designRoot: Reactor | undefined;
      readonly oldDesignRoot: Reactor | undefined;
    }) => void,
  ): IDisposable;

  onChangeComponentContainer(
    listener: (event: {
      readonly newContainer: Component;
      readonly oldContainer: Component;
      readonly component: Component;
    }) => void,
  ): IDisposable;

  beginUndoTransaction(): void;
  endUndoTransaction(): void;

  // Set new property values and put their old values on the undo stack.
  setProperties(changes: PropertySet[], opts?: { undoStop?: boolean; undo?: boolean }): void;

  // Low level getter/setting methods for storedProperties on a reactor.
  setStoredProperty(reactor: ReactorObject, property: string, value: any): void;
  getStoredProperty(reactor: ReactorObject, property: string): any;

  // Insert a new component and put it on the undo stack
  insertComponent<T extends Component>(
    parentId: ReactorId,
    propertyOrIndex: string | number,
    stateOrComponent: Properties | Component,
    noUndoStop?: boolean,
  ): T;

  moveComponent<T extends Component>(
    source: T,
    targetArray: ReactorArray<Component>,
    targetIndex?: number,
    noUndoStop?: boolean,
  ): T;

  deleteComponent(component: Component, noUndoStop?: boolean, keepSelection?: boolean): void;
}
export interface IProjectStore {}

export interface IProject {}

export type ContentId = string;
export const playContentMimeType = 'application/play-content';
export const playComponentMimeType = 'application/play-component';
export const playResourceMetaDataMimeType = 'application/play-resource-meta';
export const playCustomKitMimeType = 'application/play-custom-kit';

export type ContentCollection = Record<ContentId, Content>;

export type Content =
  | LibraryResourceContent
  | ImageContent
  | VideoContent
  | AudioContent
  | ComponentContent
  | ResourceContent
  | PendingComponentContent
  | FileContent
  | EffectContent
  | StyleContent
  | ClipboardContent;

export type LibraryResourceContent = {
  id: ContentId;
  type: 'libraryResource';
  resourceJson?: IResourceJson;
  resource?: IResource;
  key: string;
  thumbnailKey: string;
  resourceType?: string; // TODO: rename/remove
  options?: AddResourceOptions;
};

export type ImageContent = {
  id: ContentId;
  type: 'image';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type VideoContent = {
  id: ContentId;
  type: 'video';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type AudioContent = {
  id: ContentId;
  type: 'audio';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type ComponentContent = {
  id?: ContentId;
  type: 'component';
  state: Properties;
  projectId?: ProjectId;
  options?: AddComponentOptions;
};

export type EffectContent = {
  id?: ContentId;
  type: 'effect';
  state: Properties;
  projectId?: ProjectId;
  options?: AddContentOptions;
};

export type StyleContent = {
  id?: ContentId;
  type: 'style';
  state: Properties;
  projectId?: ProjectId;
  options?: AddContentOptions;
};

export type ClipboardContent = {
  id?: ContentId;
  type: 'clipboard';
  data: ClipboardData;
  projectId?: ProjectId;
  options?: AddContentOptions;
};

export type ResourceContent = {
  id: ContentId;
  type: 'resource';
  resource: IResource;
  options?: AddResourceOptions;
};

export type PendingComponentContent = {
  id: ContentId;
  type: 'pendingComponent';
  options?: AddResourceOptions & {
    componentId?: ReactorId;
  };
};

export type FileContent = {
  id: ContentId;
  type: 'file';
  file: File;
  options?: AddResourceOptions;
};

export interface IDesignerContext {
  projectModel: IProjectModel;
  projectStore: IProjectStore;
  project: Project;
  viewportContext: IViewportContext;
  addComponent: (
    state: Properties,
    options?: AddComponentOptions,
    component?: Component,
  ) => Component;
  addPage: (state: Properties) => Page;
  addEffectsAndInteractions: (state: Properties, target: Component) => void;
}

/**
 * Contains information about the designer's viewport
 */
export interface IViewportContext {
  viewportRect: DOMRect;
  transform: PanZoomTransform;
  designerMatrix: DOMMatrix;
}

export interface IDesigner {
  // TODO: mount needs project? or already has it?
  mount(container: HTMLElement, projectModel: IProjectModel, project: IProject): void;
  unmount(): void;
  play(play: boolean): void;

  validate(): void;
  invalidate(): void;
  update(changed: any): void;

  width: number;
  height: number;
  zoom: number;
  deviceWidth: number | undefined;
  deviceHeight: number | undefined;
  readonly playing: boolean;
}

export interface IWorkbench {
  underConstruction: boolean;
  onAddContent(listener: (e: IAddContentEvent) => void): IDisposable;
  setActiveTab(panel: string, activeTab: string, expanded?: boolean): void;

  addContentFromDataTransfer(
    dataTransfer: DataTransfer,
    options?: AddResourceOptions,
  ): Promise<void>;
  getResource(resId: ResourceId): Promise<IResource>;

  getLastInteractionTime(): number | undefined;
  notifyIsInteracting(interacting: boolean): void;

  addSnack(snack: WorkbenchSnack): void;
  addInteraction(event: string, triggerComponent: Component, actionComponent?: Component): void;

  notifyError(message: React.ReactNode): void;
}

export interface IAddContentEvent {
  readonly content: Content;
  readonly onDone?: () => void;
}

export class AddContentEvent implements IAddContentEvent {
  constructor(public readonly content: Content, public readonly onDone?: () => void) {}
}

export type WorkbenchSnack = {
  variant?: 'success' | 'error' | 'info' | 'warning';
  message: string;
  message2?: string;
  anchor?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

export function isRootViewContainer(component: Component): boolean {
  return component?.name === 'rootViewContainer';
}
