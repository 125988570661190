import {
  Popover as ChPopover,
  PopoverArrow as ChPopoverArrow,
  PopoverBody as ChPopoverBody,
  PopoverCloseButton as ChPopoverCloseButton,
  PopoverContent as ChPopoverContent,
  PopoverFooter as ChPopoverFooter,
  PopoverHeader as ChPopoverHeader,
} from '@chakra-ui/popover';
import React from 'react';

import type {
  PopoverArrowProps,
  PopoverBodyProps,
  PopoverCloseButtonProps,
  PopoverContentProps,
  PopoverFooterProps,
  PopoverHeaderProps,
  PopoverProps,
} from './types';

/**
 * Popover component that renders a popover on hover. See [Popover](https://v1.chakra-ui.com/docs/components/overlay/popover) for the complete documentation.
 */
export function Popover(props: PopoverProps) {
  return <ChPopover {...props} />;
}
export function PopoverArrow(props: PopoverArrowProps) {
  return <ChPopoverArrow {...props} />;
}
export function PopoverBody(props: PopoverBodyProps) {
  return <ChPopoverBody {...props} />;
}
export function PopoverCloseButton(props: PopoverCloseButtonProps) {
  return <ChPopoverCloseButton {...props} />;
}
export function PopoverContent(props: PopoverContentProps) {
  return <ChPopoverContent {...props} />;
}
export function PopoverFooter(props: PopoverFooterProps) {
  return <ChPopoverFooter {...props} />;
}
export function PopoverHeader(props: PopoverHeaderProps) {
  return <ChPopoverHeader {...props} />;
}
export { PopoverTrigger, PopoverAnchor } from '@chakra-ui/popover';
