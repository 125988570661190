export type StringDictionary = { [key: string]: string };
export type UndefinedStringDictionary = { [key: string]: string | undefined };

/**
 * Checks to see if a component is a component master or instance.
 * If it's a component master, it will be found in the `Components` object on the project.
 *
 * Note: Projects without a custom component master will not have a `Components` object at all.
 *
 * @param project - the active project
 * @param componentName - the name of the component to be checked
 * @returns
 */
export function isComponentMaster(project: any, componentName: string) {
  return !!project.Components?.[componentName];
}

export function suggestName(baseName: string, takenNames: string[], alwaysSuffix = false): string {
  // Strip trailing number (if any).
  const unsuffixed = baseName.replace(/\d+$/, '');
  return (
    unsuffixed + suggestSuffix(unsuffixed, takenNames, alwaysSuffix || unsuffixed !== baseName)
  );
}

export function suggestSuffix(
  baseName: string,
  takenNames: string[],
  alwaysSuffix = false,
  space = false,
): string {
  let n = 1;

  while (true) {
    const candidateName = !alwaysSuffix ? baseName : baseName + (space ? ' ' : '') + n;
    if (!takenNames.includes(candidateName)) {
      return !alwaysSuffix ? '' : String(n);
    }
    alwaysSuffix = true;
    n++;
  }
}

export function hexpad(s: string | number): string {
  if (typeof s === 'number') {
    s = s.toString();
  }
  return parseInt(s).toString(16).padStart(2, '0');
}

export function encode64(buffer: ArrayBuffer): string {
  return btoa(new Uint8Array(buffer).reduce((s, b) => s + String.fromCharCode(b), ''));
}

export function hex(buffer: ArrayBuffer): string {
  return [].map
    .call(new Uint8Array(buffer), (b: number) => ('00' + b.toString(16)).slice(-2))
    .join('');
}

export type Status<T> = [any] | [null, T];

/**
 *
 * A tiny util that allows the usage of async/await without a try/catch.
 * It returns an array where the first entry is any error thrown, and the
 * second is the res data.
 *
 * Usage: const [err, data] = await fromPromise(myAsyncFn())
 */
export async function fromPromise<T extends Promise<any>>(promise: T): Promise<Status<Awaited<T>>> {
  try {
    const data = await promise;
    return [null, data];
  } catch (e) {
    return [e];
  }
}

export function upperFirstLetter(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

export function lowerFirstLetter(s: string): string {
  return s[0].toLowerCase() + s.slice(1);
}

// button123 -> button, b1tton -> b1tton, b1tton -> b1tton, 13434bb -> 13434bb
export function trimNumberSuffix(s: string): string {
  return s.replace(/^(.*?)([0-9]*)$/, '$1');
}

export function isDescendant(el: HTMLElement, parentId: string): boolean {
  let isChild = false;

  if (el.id === parentId) {
    //is this the element itself?
    isChild = true;
  }

  while ((el = el.parentNode as HTMLElement)) {
    if (el.id == parentId) {
      isChild = true;
    }
  }

  return isChild;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const sep = '/';
const dupeSep = new RegExp(sep + '{1,}', 'g');

// a reasonable client side join a la node. does not take into account all the various edge
// cases of a protocol-compliant join, but should be good enough for our purposes.
export function joinPath(pathParts: string[]) {
  const [maybeProtocol, ...rest] = pathParts;

  // try just a little
  const isRelative = maybeProtocol.startsWith(sep) || maybeProtocol.startsWith('.');
  const dedupe = isRelative ? pathParts : rest;

  // we don't want to accidentally remove something like `//` from a protocol
  const noProtocol = dedupe.join(sep).replace(dupeSep, sep);

  return isRelative ? noProtocol : maybeProtocol + noProtocol;
}

export function buildUserRoute(
  userName: string,
  slug?: string,
  action: 'edit' | '' = '',
  base = '',
) {
  const validOnly = [`@${userName}`, slug, action].filter(Boolean) as string[];

  return joinPath([`/${base}`, ...validOnly]);
}

export function buildEmbeddedPlayerPath(
  userName: string,
  projId: string,
  params?: { [key: string]: string },
) {
  return buildPath(joinPath(['/p', userName, `${projId}/`]), { chrome: 'false', ...params });
}

export function buildPath(basePath: string, params?: { [key: string]: string }) {
  const encodedBase = encodeURI(basePath);

  if (!params) return encodedBase;

  const paramEntries = Object.entries(params).map(([key, value]) =>
    value ? `${key}=${encodeURIComponent(value)}` : key,
  );

  if (!paramEntries.length) return encodedBase;

  return `${encodedBase}?${paramEntries.join('&')}`;
}

export function buildUrl(
  url: string | URL,
  base?: string | URL | undefined,
  params?: { [key: string]: string },
) {
  const urlObj = new URL(url, base);

  return buildPath(decodeURI(urlObj.toString()), params);
}
