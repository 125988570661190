import { Checkbox as ChCheckbox, CheckboxGroup as ChCheckboxGroup } from '@chakra-ui/checkbox';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';

import { CheckmarkIcon, MinusIcon } from '../Icon';
import type { CheckboxProps } from '.';

type CustomIconProps = {
  isChecked?: boolean;
  isIndeterminate?: boolean;
};

const CustomIcon = ({ isIndeterminate, isChecked, ...restProps }: CustomIconProps) => {
  return isIndeterminate ? (
    <MinusIcon strokeWidth={3} {...restProps} />
  ) : (
    <CheckmarkIcon strokeWidth={3} {...restProps} />
  );
};

/**
 * Checkbox component. See [Checkbox](https://v1.chakra-ui.com/docs/components/form/checkbox) for the complete documentation.
 */
export const Checkbox = forwardRef((props: CheckboxProps, ref) => {
  return <ChCheckbox icon={<CustomIcon />} ref={ref} spacing='0.5em' {...props} />;
});

export const CheckboxGroup = ChCheckboxGroup;
