//import type { View } from '@playful/playkit/playkit';

import type { Component } from './component';

export type Container = Component & {
  children: Component[];
};

export function isContainer(component: Component): component is Container {
  return !!component.children;
}
