import { Component, ID, forEachObject, selfObjectId } from '@playful/runtime';
import { isContainer } from '@playful/runtime/container';
import { Action, Interaction, Variant, makeVariant } from '@playful/runtime/interactions';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description:
    'project migrations. slides to pages, delete width and height, migrate deck css convert .components to .children etc...',

  migrate(state) {
    const runtimeVersion = state.runtimeVersion || 0;

    if (runtimeVersion < 10) {
      // Convert Project.slides to Project.pages.
      if ((state as any).slides) {
        (state as any).pages = (state as any).slides;
        delete (state as any).slides;
      }

      // Delete Project.width and .height.
      delete (state as any).width;
      delete (state as any).height;

      // Delete Project.components (and .children from a couple test projects).
      delete (state as any).components;
      delete (state as any).children;

      // Migrate Deck CSS.
      if ((state as any).css) {
        (state as any).css = (state as any).css.replace('.slide', '.page');
        (state as any).css = (state as any).css.replace('.deck', '.project');
      }

      forEachObject(state, (obj) => {
        if (!obj.componentType) return;
        const component = obj as Component;

        // No event handlers anymore.
        delete (component as any).eventHandlers;

        // Convert Slides to Pages.
        if (component.componentType === 'Play Kit/Slide') {
          component.componentType = 'Play Kit/Page';
        }

        // Convert .components to .children.
        if (component.components) {
          component.children = component.components;
          delete component.components;
        }

        // Convert Container.clipContents to Container.overflow.
        if (isContainer(component) && component.clipContents !== undefined) {
          component.overflow = component.clipContents ? 'hide' : 'show';
          delete component.clipContents;
        }
      });

      // Convert Interaction Action arguments to be Variants.
      // Convert Trigger and Action targets to use symbolic references when appropriate.
      let key = 1;

      function migrateActions(actions: Action[], interactionComponentId: number) {
        for (const action of actions) {
          // Reference self symbolically.
          if (action.targetId === interactionComponentId) {
            action.targetId = selfObjectId;
          }
          // Give each action a unique key for rendering purposes.
          if (!action.key) {
            action.key = (key++).toString();
          }
          if (action.args) {
            const args = action.args;
            for (const argName in args) {
              const value = args[argName] as any;
              if (value.type !== undefined) {
                // Already a Variant.
                if ((value as Variant).type === 'actions') {
                  migrateActions(value.value, interactionComponentId);
                }
              } else {
                args[argName] = makeVariant(value);
              }
            }
          } else {
            // The args object isn't optional as it once was.
            action.args = {};
          }
        }
      }

      forEachObject(state, (obj) => {
        if (!obj.componentType || !obj.interactions) {
          return;
        }

        for (const interaction of obj.interactions as Interaction[]) {
          if (!interaction.key) {
            interaction.key = (key++).toString();
          }
          // Reference self symbolically.
          if (interaction.trigger.targetId === obj[ID]) {
            interaction.trigger.targetId = selfObjectId;
          }
          migrateActions(interaction.actions, obj[ID]);
        }
      });
    }

    log(`Migrated ${migration.description}`);
  },
};

export default migration;
