/**
 * Currently this will not react to the 'dark-mode' switch
 *
 */
export default {
  body: {
    fontFamily: 'body',
    color: 'gray.800',
    bg: 'white',
    transitionProperty: 'background-color',
    transitionDuration: 'normal',
    lineHeight: 'base',
  },
  '*::placeholder': { color: 'gray.400' },
  '*, *::before, &::after': { borderColor: 'gray.200', wordWrap: 'break-word' },
  /*
        This will hide the focus indicator if the element receives focus    via the mouse,
        but it will still show up on keyboard focus.
      */
  '.js-focus-visible :focus:not([data-focus-visible-added])': {
    outline: 'none',
    boxShadow: 'none',
  },
};
