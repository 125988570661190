export const tooltipTheme = {
  defaultProps: {},
  parts: [],
  baseStyle: {
    '--tooltip-bg': 'colors.gray.700',
    px: 2,
    py: 0.5,
    bg: ['var(--tooltip-bg)'],
    '--popper-arrow-bg': ['var(--tooltip-bg)'],
    color: 'whiteAlpha.900',
    borderRadius: 'sm',
    fontWeight: 'medium',
    fontSize: 'sm',
    boxShadow: 'md',
    maxW: '20rem',
    zIndex: 'tooltip',
  },
  variants: {},
  sizes: {},
};
