import { Link as ChLink } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';
import { Link as WLink } from 'wouter';

import { Button } from '../Button';
import type { ButtonLinkProps, LinkProps } from './types';

/**
 * Link component that renders an 'a' tag. See [Link](https://v1.chakra-ui.com/docs/components/navigation/link) for the complete documentation.
 */
export const Link = forwardRef((props: LinkProps, ref) => {
  return <ChLink ref={ref} {...props} />;
});

export type RouteLinkProps = Omit<LinkProps, 'href'> & { href: string };

export const RouteLink = forwardRef(({ href, ...props }: RouteLinkProps, ref) => {
  return (
    <WLink href={href}>
      <Link ref={ref} {...props} />
    </WLink>
  );
});

export const ButtonLink = forwardRef((props: ButtonLinkProps, ref) => {
  return <Button as={Link} role='button' ref={ref} {...props} />;
});
