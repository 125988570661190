/**
 * Inspiration taken from https://github.com/chrvadala/transformation-matrix
 */

import type { Corners, Point, Rectangle } from './runtime';
import { mapObject } from './util';

export type Matrix = {
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
};

export type TranformationTSR = {
  translate: {
    tx: number;
    ty: number;
  };
  scale: {
    sx: number;
    sy: number;
  };
  rotation: {
    angle: number;
    degrees: number;
  };
};

const { cos, sin, PI } = Math;

/**
 * Identity matrix
 */
export function identity(): Matrix {
  return {
    a: 1,
    c: 0,
    e: 0,
    b: 0,
    d: 1,
    f: 0,
  };
}

/**
 * Check if the object is a Matrix
 */
export function isMatrix(object: any): object is Matrix {
  return (
    isObject(object) &&
    'a' in object &&
    isNumeric(object.a) &&
    'b' in object &&
    isNumeric(object.b) &&
    'c' in object &&
    isNumeric(object.c) &&
    'd' in object &&
    isNumeric(object.d) &&
    'e' in object &&
    isNumeric(object.e) &&
    'f' in object &&
    isNumeric(object.f)
  );
}

export function applyToPoint(matrix: Matrix, point: Point): Point {
  return {
    x: matrix.a * point.x + matrix.c * point.y + matrix.e,
    y: matrix.b * point.x + matrix.d * point.y + matrix.f,
  };
}

export function applyToCorners(matrix: Matrix, corners: Corners) {
  return mapObject(corners, (p: Point) => applyToPoint(matrix, p)) as Corners;
}

export function applyToRectangle(matrix: Matrix, rect: Rectangle): Rectangle {
  return {
    ...applyToPoint(matrix, { x: rect.x, y: rect.y }),
    w: rect.w,
    h: rect.h,
  };
}

export function applyToPoints(matrix: Matrix, points: Point[]): Point[] {
  return points.map((point) => applyToPoint(matrix, point));
}

/**
 * Merge multiple matrices into one
 */
export function compose(...matrices: Matrix[] | Array<Matrix[]>) {
  return transform(...matrices);
}

/**
 * Merge multiple matrices into one
 */
export function transform(...matrices: Matrix[] | Array<Matrix[]>): Matrix {
  matrices = (Array.isArray(matrices[0]) ? matrices[0] : matrices) as Matrix[];

  const multiply = (m1: Matrix, m2: Matrix) => {
    return {
      a: m1.a * m2.a + m1.c * m2.b,
      c: m1.a * m2.c + m1.c * m2.d,
      e: m1.a * m2.e + m1.c * m2.f + m1.e,
      b: m1.b * m2.a + m1.d * m2.b,
      d: m1.b * m2.c + m1.d * m2.d,
      f: m1.b * m2.e + m1.d * m2.f + m1.f,
    };
  };

  switch (matrices.length) {
    case 0:
      throw new Error('no matrices provided');

    case 1:
      return matrices[0];

    case 2:
      return multiply(matrices[0], matrices[1]);

    default: {
      const [m1, m2, ...rest] = matrices;
      const m = multiply(m1, m2);
      return transform(m, ...rest);
    }
  }
}

/**
 * Calculate a rotation matrix
 */
export function rotate(angle: number, cx?: number, cy?: number) {
  const cosAngle = cos(angle);
  const sinAngle = sin(angle);
  const rotationMatrix = {
    a: cosAngle,
    c: -sinAngle,
    e: 0,
    b: sinAngle,
    d: cosAngle,
    f: 0,
  };
  if (isUndefined(cx) || isUndefined(cy)) {
    return rotationMatrix;
  }

  return transform([translate(cx, cy), rotationMatrix, translate(-cx, -cy)]);
}

/**
 * Calculate a rotation matrix with a DEG angle
 */
export function rotateDEG(angle: number, cx?: number, cy?: number): Matrix {
  return rotate((angle * PI) / 180, cx, cy);
}

/**
 * Calculate a matrix that is the inverse of the provided matrix
 */
export function inverse(matrix: Matrix): Matrix {
  // http://www.wolframalpha.com/input/?i=Inverse+%5B%7B%7Ba,c,e%7D,%7Bb,d,f%7D,%7B0,0,1%7D%7D%5D

  const { a, b, c, d, e, f } = matrix;

  const denom = a * d - b * c;

  return {
    a: d / denom,
    b: b / -denom,
    c: c / -denom,
    d: a / denom,
    e: (d * e - c * f) / -denom,
    f: (b * e - a * f) / denom,
  };
}

export function scale(sx: number, sy?: number, cx?: number, cy?: number): Matrix {
  if (isUndefined(sy)) sy = sx;

  const scaleMatrix = {
    a: sx,
    c: 0,
    e: 0,
    b: 0,
    d: sy,
    f: 0,
  };

  if (isUndefined(cx) || isUndefined(cy)) {
    return scaleMatrix;
  }

  return transform([translate(cx || 0, cy), scaleMatrix, translate(-cx, -cy)]);
}

/**
 * Rounds all elements of the given matrix using the given precision
 */
export function smoothMatrix(matrix: Matrix, precision = 10000000000): Matrix {
  return {
    a: Math.round(matrix.a * precision) / precision,
    b: Math.round(matrix.b * precision) / precision,
    c: Math.round(matrix.c * precision) / precision,
    d: Math.round(matrix.d * precision) / precision,
    e: Math.round(matrix.e * precision) / precision,
    f: Math.round(matrix.f * precision) / precision,
  };
}

export function roundToPrecision(n: number, precision = 10000000000): number {
  return Math.round((n + Number.EPSILON) * precision) / precision;
}

export function translate(tx: number, ty = 0): Matrix {
  return {
    a: 1,
    c: 0,
    e: tx,
    b: 0,
    d: 1,
    f: ty,
  };
}

export function flipX(): Matrix {
  return {
    a: 1,
    c: 0,
    e: 0,
    b: 0,
    d: -1,
    f: 0,
  };
}

/**
 * Transformation matrix that mirrors on y-axis
 */
export function flipY(): Matrix {
  return {
    a: -1,
    c: 0,
    e: 0,
    b: 0,
    d: 1,
    f: 0,
  };
}

/**
 * Sets the transform origin of a matrix
 */
export function setTransformOrigin(matrix: Matrix, cx: number, cy: number) {
  return transform(translate(cx, cy), matrix, inverse(translate(cx, cy)));
}

export function isUndefined(val: any): val is undefined {
  return typeof val === 'undefined';
}

export function isNumeric(n: any) {
  return typeof n === 'number' && !Number.isNaN(n) && Number.isFinite(n);
}

export function isObject(obj: any) {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

/**
 * Serialize an affine matrix to a string that can be used with CSS or SVG
 */
export function toMatrixString(matrix: Matrix) {
  return `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${matrix.e},${matrix.f})`;
}

/**
 * Decompose a matrix into translation, scaling and rotation components, optionally
 * take horizontal and vertical flip in to consideration.
 * Note this function decomposes a matrix in rotation -> scaling -> translation order. I.e. for
 * certain translation T {tx, ty}, rotation R and scaling S { sx, sy }, it's only true for:
 *  decomposeTSR(compose(T, S, R)) === { translate: T, rotation: R, scale: S }
 * composing in a different order may yield a different decomposition result.
 * and rotation components.
 */
export function decomposeTSR(matrix: Matrix, flipX = false, flipY = false): TranformationTSR {
  // Remove flip from the matrix first - flip could be incorrectly interpreted as
  // rotations (e.g. flipX + flipY = rotate by 180 degrees).
  // Note flipX is a vertical flip, and flipY is a horizontal flip.
  if (flipX) {
    if (flipY) {
      matrix = compose(matrix, scale(-1, -1));
    } else {
      matrix = compose(matrix, scale(1, -1));
    }
  } else if (flipY) {
    matrix = compose(matrix, scale(-1, 1));
  }

  const a = matrix.a;
  const b = matrix.b;
  const c = matrix.c;
  const d = matrix.d;
  let scaleX, scaleY, rotation;

  if (a !== 0 || c !== 0) {
    const hypotAc = Math.hypot(a, c);
    scaleX = hypotAc;
    scaleY = (a * d - b * c) / hypotAc;
    const acos = Math.acos(a / hypotAc);
    rotation = c > 0 ? -acos : acos;
  } else if (b !== 0 || d !== 0) {
    const hypotBd = Math.hypot(b, d);
    scaleX = (a * d - b * c) / hypotBd;
    scaleY = hypotBd;
    const acos = Math.acos(b / hypotBd);
    rotation = Math.PI / 2 + (d > 0 ? -acos : acos);
  } else {
    scaleX = 0;
    scaleY = 0;
    rotation = 0;
  }

  // put the flip factors back
  if (flipY) {
    scaleX = -scaleX;
  }

  if (flipX) {
    scaleY = -scaleY;
  }

  return {
    translate: { tx: matrix.e, ty: matrix.f },
    scale: { sx: scaleX, sy: scaleY },
    rotation: { angle: rotation, degrees: (rotation * 180) / Math.PI },
  };
}
