import { useCallback, useReducer, useRef } from 'react';

/**
 * A helper hook that allows you to manage a state that will not trigger React re-renders
 * unless you call forceUpdate
 * You can setState either directly:
 * state.foo = 'bar';
 * or with setState
 * setState({ foo: 'bar' });
 * It also accepts a function which provides the current value:
 * setState((oldValue) => {foo: oldValue + 5 })
 *
 * If you have a lot of `useRef` calls in your component consider replacing them with this hook
 */
export function useRefState<T>(
  initialValue: T,
): [() => T, (value: ((current: T) => T) | T) => T, () => void] {
  const [, forceUpdate] = useReducer((c) => c + 1, 0);
  const ref = useRef(initialValue);
  const get = useCallback(() => ref.current, []);
  const set = useCallback((value: ((current: T) => T) | T) => {
    if (typeof value === 'function') {
      ref.current = (value as (current: T) => T)(ref.current);
    } else {
      ref.current = value;
    }
    return ref.current;
  }, []);

  return [get, set, forceUpdate];
}
