import './zone_config';
import './zone_z';

import { ZoneContextManager } from '@opentelemetry/context-zone-peer-dep';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import type { Config } from '@playful/config';

import type { UserProperties } from '../events/identify';
import { enableWebVitals } from '../webvitals';
import { OTLPTraceExporter } from './exporter-trace-otlp-http/OTLPTraceExporter';
import { SpanAttributesProcessor } from './processor';

let processor: SpanAttributesProcessor;

export function setUserAttributes(id?: string, props?: UserProperties) {
  processor?.setUserAttributes(id, props);
}

export function enableTracing(cfg: Config) {
  if (!cfg.traceEndpoint) {
    return;
  }
  // https://github.com/open-telemetry/opentelemetry-js/issues/3062
  const headers: Record<string, any> = {};
  if (cfg.honeycombKey) {
    headers['x-honeycomb-team'] = cfg.honeycombKey;
  }
  const exporter = new OTLPTraceExporter({
    headers,
    url: cfg.traceEndpoint,
  });
  const provider = new WebTracerProvider({
    resource: new Resource({
      environment: cfg.environment,
      branch: cfg.gitBranch,
      [SemanticResourceAttributes.SERVICE_NAME]: 'browser',
    }),
  });
  processor = new SpanAttributesProcessor();
  provider.addSpanProcessor(processor);
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  provider.register({
    contextManager: new ZoneContextManager(),
  });

  enableWebVitals();
}
