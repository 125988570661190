import type { Component, Project, ProjectInfo, Properties } from '@playful/runtime';
import type { Endpoint, Remote } from 'comlink';

import { getComponentProperties } from '../component';
import { deserializeEvent, isPlaySerializedEvent } from './events';
import type { GenerateThumbnailOptions } from './plugins/thumbnailer';
import type { ActionEvent, ClientApi, HostApi, Listeners, SandboxEvent } from './types';

export type RPC<Api> = Remote<Api>;

export function getHostApi(
  component: Component,
  ep: Endpoint,
  {
    comlinkListeners,
    onDispatchEvent: handleDispatchEvent,
  }: {
    comlinkListeners: EventListenerOrEventListenerObject[];
    onDispatchEvent: (event: Event) => void;
  },
) {
  const sandbox = component._iframe;
  const listeners: Listeners = {
    onAction: [],
    onCommand: [],
    onReady: [],
    onThumbnail: undefined,
    onUpdate: [],
  };

  const client: ClientApi = {
    init(
      action?: (event: ActionEvent) => void,
      command?: (command: string) => void,
      update?: (props: Properties, changed?: { [prop: string]: boolean }) => void,
    ) {
      if (action) listeners.onAction.push(action);
      if (command) listeners.onCommand.push(command);

      // This adds the listener AFTER the one established in client.ts awaitBridgeReady
      // which updates play.properties with the updated property values.
      if (update) listeners.onUpdate.push(update);

      // "assuming there will be an update call with initial properties I think
      // that’ll be good enough until we inject them up front" - darrin
      // Yes! Trigger the initial update notification which also matches the
      // behavior of the onUpdate() form.
      update?.(...getPropertiesAsChanges(component));
    },

    /**
     * Callback to be triggered when the Play bridge has connected. If called
     * after the bridge is active, the callback is immediately executed.
     */
    ready(cb: () => void) {
      if (listeners.onReady === undefined) {
        cb();
        return;
      }

      listeners.onReady.push(cb);
    },

    notifyReady() {
      listeners.onReady?.forEach((it) => it());
      listeners.onReady = undefined;
    },

    notifyEvent({ type, ...rest }: SandboxEvent) {
      // TODO TODO if no interaction is listening for it, should we skip sending it
      // over the wire?
      client.dispatchEvent(new CustomEvent(type, rest));
    },

    /**
     * register a callback to handle play requesting a thumbnail from inside
     * the IFAME it's hosted in.
     */

    onThumbnailNeeded(
      cb: (
        width: number,
        height: number,
        options: GenerateThumbnailOptions,
      ) => Promise<ImageData | undefined>,
    ) {
      listeners.onThumbnail = cb;
    },

    /**
     * Retrieves the properties of the current project
     */
    getProject(): Partial<Project & ProjectInfo> {
      const {
        project,
        project: { info },
      } = component;

      // TODO: what if any of these are useful. The ID is probably useful if the
      // author of the sandbox component uses it to separate stored data for
      // example.
      return {
        // created: info.created,
        id: info.id,
        // locked: info.locked,
        // modified: info.modified,
        // name: info.name,
        // owner: info.owner,
        // ownerName: info.ownerName,
        // project: info.project,
        // published: info.published,
        // publishedHistory: info.publishedHistory,
        // publishedProject: info.publishedProject,
        // publishedVersion: info.publishedVersion,
        // sharing: info.sharing,
        // tags: info.tags,
        title: info.title,
        // version: info.version,
        designMode: project.designMode,
        scale: project.rootView.getEffectiveScale(),
      };
    },

    /**
     * Allows for inner sandbox to perform updates to a component’s properties.
     *
     * @param properties
     */
    setProperties(properties: Properties) {
      // TODO: update play.properties
      for (const key in properties) {
        component[key] = properties[key];
      }
    },

    /**
     * Allows in inner runtime to dispatch an event which can be the initial
     * trigger for an interaction.
     *
     * @param detail the payload to dispatch
     */
    dispatchEvent(detail: any) {
      if (detail && detail.type) {
        handleDispatchEvent(detail);
      } else if (isPlaySerializedEvent(detail)) {
        handleDispatchEvent(deserializeEvent(sandbox, detail));
      } else {
        handleDispatchEvent(new CustomEvent('sandbox', { detail }));
      }
    },

    // TODO
    // If commands are going to be supported we should decide if the sandbox
    // code is responsible for registering the command (play.registerCommand)
    // or if we provide UI affordance. It seems like if custom commands make
    // sense for no code user components, then UI seems sensible. But if we
    // don't need them, or we only have that ability in the Sandbox component.
    // then programiatic registration seems sensible (and gives conditionality).
    //
    // There's a very similar question regarding if a sandbox can expose custom
    // actions which can be triggered by other components. That could be awesome.

    /**
     * Allows inner runtime to listen for an action to be triggered
     *
     * @param callback
     */
    onAction(callback: (event: ActionEvent) => void) {
      listeners.onAction.push(callback);
    },

    /**
     * Allows inner runtime to listen for an command to be triggered
     *
     * @param callback
     */
    onCommand(callback: (command: string) => void) {
      listeners.onCommand.push(callback);
    },

    onUpdate(callback: (props: Properties, changed: { [prop: string]: boolean }) => void) {
      listeners.onUpdate.push(callback);

      callback(...getPropertiesAsChanges(component));
    },

    //--------------------------------------------------------------------------

    log(...entry: any): void {
      console.log(...['[Play]', `(${component.name}) =>`, ...entry]);
    },

    ping(): string {
      return 'PONG';
    },
  };

  const host: HostApi = {
    detach() {
      //console.log('[Play] Detaching:', component.name);
      comlinkListeners.forEach((it) => ep.removeEventListener('message', it));
    },

    // for now a string, later maybe a more rich payload
    dispatchAction(type: string, payload: any) {
      const properties = getComponentProperties(component);
      const event = { type, payload, properties };

      listeners.onAction.forEach((it) => it(event));
    },

    // TODO - sandboxes don't have custom commands, play.registerCommand?
    dispatchCommand(command: string) {
      listeners.onCommand.forEach((it) => it(command));
    },

    async dispatchThumbnailNeeded(
      width: number,
      height: number,
      options: GenerateThumbnailOptions,
    ) {
      return listeners.onThumbnail?.(width, height, options) ?? undefined;
    },

    dispatchUpdate(changed: { [prop: string]: boolean }) {
      const props = getComponentProperties(component);

      const changes: Properties = {};

      for (const key in changed) {
        const value = props[key];
        if (typeof value !== 'function') {
          changes[key] = props[key];
        }
      }

      listeners.onUpdate.forEach((it) => {
        it(props, changed);
      });
    },
  };

  return {
    client,
    host,
  };
}

/**
 * For the initial update call that contains all the initial properties, we have
 * this function to grab all the relevant properties and then hand them off
 * paired with a changes object representing all of them to match the shape
 * that will triggered by subsequent updates.
 *
 * @param component
 * @param changes
 */
function getPropertiesAsChanges(component: Component): [Properties, { [key: string]: boolean }] {
  const properties = getComponentProperties(component);
  const allPropsAsChanges = Object.keys(properties).reduce<{ [prop: string]: boolean }>(
    (changes, key) => {
      changes[key] = true;
      return changes;
    },
    {},
  );

  return [properties, allPropsAsChanges];
}
