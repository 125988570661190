import { applyToPoint, compose, rotateDEG, scale } from './matrix';
import type { Corners, Point, Rectangle } from './runtime';

export type ViewState = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  scale: number;
  fontSize?: number;
  sizeMode?: string;
  [property: string]: any;
};
type Range = { min: number; max: number };

export function rotatedScaledPointsFromRectangle(rect: ViewState): Point[] {
  const center: Point = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };

  return pointsFromRectangle(rect).map((point) => {
    const { x, y } = rotateScalePointAroundOrigin(point, center, rect.scale, rect.rotation);

    return { x, y } as Point;
  });
}

export function viewStatesIntersect(r1: ViewState, r2: ViewState) {
  const rotR1 = rotatedScaledPointsFromRectangle(r1);
  const rotR2 = rotatedScaledPointsFromRectangle(r2);

  const axes = [...orthogonals(rotR1), ...orthogonals(rotR2)];

  for (const axis of axes) {
    const range1 = getProjectedRange(rotR1, axis);
    const range2 = getProjectedRange(rotR2, axis);

    if (range1.max < range2.min || range2.max < range1.min) {
      return false;
    }
  }

  return true;
}

export function viewCornersIntersect(r1: Corners, r2: Corners) {
  const r1Points = pointsFromCorners(r1);
  const r2Points = pointsFromCorners(r2);

  const axes = [...orthogonals(r1Points), ...orthogonals(r2Points)];

  for (const axis of axes) {
    const range1 = getProjectedRange(r1Points, axis);
    const range2 = getProjectedRange(r2Points, axis);

    if (range1.max < range2.min || range2.max < range1.min) {
      return false;
    }
  }

  return true;
}

export function getRectangleFromCorners(corners: Corners): Rectangle {
  const { tl, tr, bl, br } = corners;

  const x1 = Math.min(tl.x, tr.x, bl.x, br.x);
  const y1 = Math.min(tl.y, tr.y, bl.y, br.y);
  const x2 = Math.max(tl.x, tr.x, bl.x, br.x);
  const y2 = Math.max(tl.y, tr.y, bl.y, br.y);

  return { x: x1, y: y1, w: x2 - x1, h: y2 - y1 };
}

export function getCornersFromViewState(view: ViewState): Corners {
  return {
    tl: { x: view.x, y: view.y },
    tr: { x: view.x + view.width, y: view.y },
    bl: { x: view.x, y: view.y + view.height },
    br: { x: view.x + view.width, y: view.y + view.height },
  };
}

export function getCornersFromRectangle(rect: Rectangle): Corners {
  return {
    tl: { x: rect.x, y: rect.y },
    tr: { x: rect.x + rect.w, y: rect.y },
    bl: { x: rect.x, y: rect.y + rect.h },
    br: { x: rect.x + rect.w, y: rect.y + rect.h },
  };
}

export function rotateScalePointAroundOrigin(
  point: Point,
  origin: Point,
  _scale: number,
  rotDegrees: number,
): Point {
  return applyToPoint(
    compose(rotateDEG(rotDegrees, origin.x, origin.y), scale(_scale, _scale, origin.x, origin.y)),
    point,
  );
}

// Return a Rectangle with a top-left origin and integer values.
export function normalizeRect(x1: number, y1: number, x2: number, y2: number): Rectangle {
  return {
    x: Math.min(x1, x2),
    y: Math.min(y1, y2),
    w: Math.abs(x1 - x2),
    h: Math.abs(y1 - y2),
  };
}

export function unionAndNormalizeRect(a?: Rectangle, b?: Rectangle): Rectangle | undefined {
  if (!a) return b;
  if (!b) return a;
  const x = Math.min(a.x, b.x);
  const y = Math.min(a.y, b.y);
  const x2 = Math.max(a.x + a.w, b.x + b.w);
  const y2 = Math.max(a.y + a.h, b.y + b.h);
  return normalizeRect(x, y, x2, y2);
}

function pointsFromRectangle(rect: ViewState): Point[] {
  return [
    { x: rect.x, y: rect.y },
    { x: rect.x + rect.width, y: rect.y },
    { x: rect.x + rect.width, y: rect.y + rect.height },
    { x: rect.x, y: rect.y + rect.height },
  ];
}

function getProjectedRange(points: Point[], axis: Point): Range {
  const range: Range = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER };

  for (const point of points) {
    const projected = axis.x * point.x + axis.y * point.y;
    if (projected < range.min) {
      range.min = projected;
    }
    if (projected > range.max) {
      range.max = projected;
    }
  }

  return range;
}

function orthogonals(points: Point[]): Point[] {
  const ret = [];

  for (let i = 0; i < points.length; i++) {
    const p1 = points[i];
    const p2 = points[(i + 1) % points.length];

    ret.push(
      orthogonal({
        x: p2.x - p1.x,
        y: p2.y - p1.y,
      }),
    );
  }

  return ret;
}

function orthogonal(vector: Point) {
  return normalize({ x: -vector.y, y: vector.x });
}

function normalize(vector: Point) {
  const magnitude = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
  return { x: vector.x / magnitude, y: vector.y / magnitude };
}

function pointsFromCorners(corners: Corners) {
  const { tl, tr, br, bl } = corners;
  return [tl, tr, br, bl];
}
