import { ID, forEachObject, parentObjectId } from '@playful/runtime';
import type { Action, Interaction, Variant } from '@playful/runtime/interactions';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description: 'Convert Action targets to use symbolic references to parent',

  migrate(state) {
    const runtimeVersion = state.runtimeVersion || 0;

    // Convert Action targets to use symbolic references to parent.
    if (runtimeVersion < 11) {
      function migrateActions(actions: Action[], parentComponentId: number) {
        for (const action of actions) {
          // Reference parent symbolically.
          if (parentComponentId !== undefined && action.targetId === parentComponentId) {
            action.targetId = parentObjectId;
            //console.log('migrated action target to parent');
          }
          if (action.args) {
            const args = action.args;
            for (const argName in args) {
              const value = args[argName] as any;
              if (value.type !== undefined) {
                if ((value as Variant).type === 'actions') {
                  migrateActions(value.value, parentComponentId);
                }
              }
            }
          }
        }
      }

      forEachObject(state, (obj) => {
        if (!obj.componentType || !obj.interactions) {
          return;
        }

        for (const interaction of obj.interactions as Interaction[]) {
          migrateActions(interaction.actions, obj?.parent?.[ID]);
        }
      });

      // re-run the removal of these imports
      state.Imports = state.Imports?.filter(
        ({ name }) =>
          name !== 'Shapes' &&
          name !== 'Location Component' &&
          name !== 'Mouse' &&
          name !== 'Squircle',
      );
    }

    log(`Migrated ${migration.description}`);
  },
};

export default migration;
