import type { AxiosRequestConfig } from 'axios';

import { axiosRequest } from './axiosRequest';
import type { UserFlags } from './flags';

// Dup from @playful/runtime. Consider a `types` package
export type ResourceId = string;
export type UserId = string;

export type User = {
  id: UserId;
  email?: string;
  name: string;
  userType?: string;
  profileProject?: string;
  created?: number;
  uploadsResourceId: ResourceId;
};

/**
 * A public-facing User.
 */
export type PublicUser = Pick<User, 'id' | 'profileProject' | 'name'>;

// Convert a User to a PublicUser
export function publicUserFromUser(user: User): PublicUser {
  return {
    id: user.id,
    profileProject: user.profileProject,
    name: user.name,
  };
}

// Fetch a user
export async function getUser(userId: UserId): Promise<User> {
  const { data } = await axiosRequest(`users/${userId}`);
  return data;
}

// Update a user
export type UserUpdates = {
  profileProject?: string;
};
export async function updateUser(userId: UserId, updates: UserUpdates): Promise<User> {
  const { data } = await axiosRequest(`users/${userId}`, {
    method: 'POST',
    withCredentials: true,
    data: updates,
  });
  return data;
}

// Fetch a user's flags
export async function getUserFlags(userId: UserId): Promise<UserFlags> {
  const { data } = await axiosRequest(`users/${userId}/flags`);
  return data;
}

// Update a user's flags
export async function updateUserFlags(
  userId: UserId,
  flags: Partial<UserFlags>,
): Promise<UserFlags> {
  const { data } = await axiosRequest(`users/${userId}/flags`, {
    method: 'POST',
    withCredentials: true,
    data: flags,
  });
  return data;
}

// Check a username
export async function checkUsername(username: string, config: AxiosRequestConfig = {}) {
  const { data } = await axiosRequest(`check_username/${username}`, config);
  return data;
}

// Set a user's username
export async function setUsername(userId: UserId, username: string): Promise<User> {
  const { data } = await axiosRequest(`users/${userId}/set_username`, {
    method: 'POST',
    withCredentials: true,
    data: { name: username },
  });
  return data;
}
