import { Tooltip as ChTooltip } from '@chakra-ui/tooltip';
import React from 'react';

import type { TooltipProps } from './types';

/**
 * Tooltip component that renders a tooltip on hover. See [Tooltip](https://v1.chakra-ui.com/docs/components/overlay/tooltip) for the complete documentation.
 */
export function Tooltip(props: TooltipProps) {
  return <ChTooltip {...props} />;
}
