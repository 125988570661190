import {
  ChangeListener,
  ChangeType,
  ID,
  IDisposable,
  ReactorArray,
  ReactorId,
  isReactor,
} from './reactor';
import type { CreateReactorOptions, ReactorFactory } from './reactorFactory';

export function createReactorArray(
  factory: ReactorFactory,
  array: [],
  options?: CreateReactorOptions,
): ReactorArray {
  function notifyListeners(
    property: PropertyKey,
    newValue: any,
    oldValue: any,
    type: ChangeType,
  ): void {
    // TODO: batch? asyncify?
    for (const listener of changeListeners) {
      listener(self, property, newValue, oldValue, type);
    }
  }

  const changeListeners: ChangeListener[] = [];

  const values = array.slice() as any;

  values.onPropertyChange = function (listener: ChangeListener): IDisposable {
    changeListeners.push(listener);
    return {
      dispose: () => changeListeners.splice(changeListeners.indexOf(listener), 1),
    };
  };

  values.dispose = function (): void {
    if (self[ID] === 0) {
      return;
    }
    for (const value of values) {
      if (isReactor(value)) {
        value.dispose();
      }
    }

    if (!options?.noId) {
      factory.removeReactor(self[ID]);
      Object.defineProperty(values, ID, {
        enumerable: false,
        writable: false,
        value: 0,
      });
    }
  };

  // Hide properties one wouldn't expect on an array.
  for (const property of ['onPropertyChange', 'dispose']) {
    const descriptor = Object.getOwnPropertyDescriptor(values, property);
    if (descriptor) {
      descriptor.enumerable = false;
      descriptor.configurable = false;
      Object.defineProperty(values, property, descriptor);
    }
  }

  // Use values for the target. Non-overidden traps, e.g. defineProperty will act on it.
  const self = new Proxy(values, {
    get(target: Array<any>, property: string, receiver: any): any {
      switch (property) {
        case '_isReactor':
          return true;

        // Override these Array methods to provide 'add' and 'remove' notifications.
        case 'push':
          return (...args: any) => {
            let index = target.length;
            const length = target.push(...args);
            for (const arg of args) {
              const property = (index++).toString();
              notifyListeners(property, arg, undefined, 'add');
            }
            return length;
          };

        case 'unshift':
          return (...args: any) => {
            let index = 0;
            const length = target.unshift(...args);
            for (const arg of args) {
              const property = (index++).toString();
              notifyListeners(property, arg, undefined, 'add');
            }
            return length;
          };

        case 'splice':
          return (start: number, deleteCount?: number, ...args: any) => {
            const deleted = target.splice(start, deleteCount!, ...args);
            if (deleted && deleteCount) {
              for (let i = 0; i < deleteCount; i++) {
                // TODO: dispose oldValue if Reactor?
                notifyListeners((i + start).toString(), undefined, deleted[i], 'remove');
              }
            }
            for (const arg of args) {
              const property = (start++).toString();
              notifyListeners(property, arg, undefined, 'add');
            }
            return deleted;
          };

        case 'shift':
          return () => {
            const removed = target.shift();
            // TODO: dispose removed if Reactor?
            notifyListeners('0', undefined, removed, 'remove');
            return removed;
          };

        case 'pop':
          return () => {
            const removed = target.pop();
            // TODO: dispose removed if Reactor?
            notifyListeners(target.length.toString(), undefined, removed, 'remove');
            return removed;
          };
      }
      return Reflect.get(target, property, receiver);
    },

    // TODO: rewrite as defineProperty trap? More comprehensive and bullet proof?
    set(target: Array<any>, property: PropertyKey, value: any, receiver: any): boolean {
      if (property === ID) {
        throw `ID is readonly`;
      }

      const oldValue = Reflect.get(target, property, receiver);
      // TODO: dispose oldValue if Reactor?
      Reflect.set(target, property, value, receiver);

      // Notify listeners of value property change.
      if (value !== oldValue && property !== 'length') {
        notifyListeners(property, value, oldValue, 'change');
      }

      return true;
    },

    deleteProperty(target: any, property: PropertyKey): any {
      if (property in target) {
        const oldValue = target[property];
        const retValue = Reflect.deleteProperty(target, property);
        notifyListeners(property, undefined, oldValue, 'remove');
        // TODO: dispose oldValue if Reactor?
        return retValue;
      } else {
        return true;
      }
    },

    /*
    // Strip everything except expected array properties. Anything else just confuses everyone.
    // The symbol check is to keep React DevTools happy.
    // TODO: defineProperty enumerable=false what we don't want to expose and remove this.
    getOwnPropertyDescriptor(target: any, property: PropertyKey): PropertyDescriptor | undefined {
      if (typeof property === 'symbol' || !isNaN(parseInt(property as any))) {
        return Reflect.getOwnPropertyDescriptor(target, property);
      }
      return undefined;
    },
    */
  });

  if (!options?.noId) {
    const id = (array as any)[ID] as ReactorId;
    values[ID] = factory.addReactor(self, id);
    Object.defineProperty(values, ID, {
      enumerable: false,
      configurable: true,
      writable: false,
    });
  }

  return self;
}
