export function isObjectNotArray(value: any): boolean {
  return value && typeof value === 'object' && !Array.isArray(value);
}

// NOTE: Performance of this is terrible.
export function generateUUID(): string {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string): string => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function isObjectOrArray(value: any): boolean {
  return value && typeof value === 'object';
}

export function mapObject<T, U>(object: Record<string, T>, cb: (val: T, key: string) => U) {
  return Object.keys(object).reduce((result, key) => {
    const value = object[key];

    result[key] = cb(value, key);

    return result;
  }, {} as Record<string, U>);
}

/**
 * Deep extend two or more objects.
 * @param target
 * @param ...sources
 */
export function extendDeep(target: any, ...sources: any): any {
  for (const source of sources) {
    for (const key in source) {
      if (key in target) {
        if (isObjectNotArray(target[key]) && isObjectNotArray(source[key])) {
          extendDeep(target[key], source[key]);
        }
      } else {
        if (isObjectNotArray(source[key])) {
          target[key] = {};
          extendDeep(target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      }
    }
  }
  return target;
}

// Join paths with a /
export function pathJoin(parts: string[]): string {
  const exp = /\/{1,}/g;
  return parts.join('/').replace(exp, '/');
}

// Return the <dir> part of <dir>/<file>.<ext>
export function dirName(path: string): string {
  return path.substring(0, path.lastIndexOf('/'));
}

// foo.ext -> foo, foo.bar.ext -> foo.bar
export function trimFileExtension(s: string): string {
  return s.replace(/\.[^/.]+$/, '');
}

// Convert the string into a valid Javascript identifier by replacing invalid chars
// with underscores and prepending an underscore if it starts with a digit.
export function stringToIdentifier(s: string): string {
  s = s.replace(/\W/g, '_');
  if (s[0] >= '0' && s[0] <= '9') {
    s = '_' + s;
  }
  return s;
}

export function getShadowDomAwareTarget(event: Event): EventTarget | null {
  if (event.composedPath) {
    return event.composedPath()[0];
  }

  let ret: Element | null = event.target as Element;

  while (ret?.shadowRoot != null) {
    ret = ret.shadowRoot.activeElement;
  }

  return ret;
}

// Simple equality: must hold the same values in the same order.
export function arraysEqual(a: any[], b: any[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function dumpPrototypeChain(p: any) {
  const names = [];
  while (p) {
    names.push(p.hasOwnProperty('iam') ? p.iam : 'unknown');
    p = Reflect.getPrototypeOf(p);
  }
  console.log(names.join(' -> '));
}

export function appendSearchParam(url: string, param: string, value: string) {
  const parsed = new URL(url, document.baseURI);
  parsed.searchParams.append(param, value);
  return parsed.toString();
}
