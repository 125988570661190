import type { MigrationId, MigrationRegistry } from '../types';
import { context } from './bundleContext';

export const loadMigrations = ({
  knownMigrations,
  migrations,
}: {
  knownMigrations: Set<MigrationId>;
  migrations: MigrationRegistry;
}) => {
  //------------------------------------------------------------------------------
  // Use webpack to import all migrations in the directory and index them.
  //------------------------------------------------------------------------------
  //
  const DateStampRegex = /([0-9]+)-.*?\.ts$/;

  context.keys().forEach((key) => {
    const test = DateStampRegex.exec(key);

    if (test) {
      const [, idStr] = test;
      const id = idStr as MigrationId;
      const mod = context(key);

      if (!mod.default) {
        throw new Error(
          `unable to load migration ${key}. It appears syntactically valid, but maybe forgot to export default?`,
        );
      }

      knownMigrations.add(id);
      migrations[id] = mod.default;
      if (mod.default.retires) {
        for (const retire of mod.default.retires) {
          knownMigrations.add(retire);
        }
      }
    }
  });
  //
  //------------------------------------------------------------------------------
};
