import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const tabsTheme = {
  defaultProps: { size: 'md', variant: 'line', colorScheme: 'blue' },
  parts: ['root', 'tab', 'tablist', 'tabpanel', 'tabpanels', 'indicator'],
  baseStyle: ({ orientation }: StyleFunctionProps) => ({
    root: { display: 'block' },
    tab: {
      fontWeight: 700,
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _focus: { zIndex: 1, boxShadow: 'outline' },
    },
    tablist: {
      justifyContent: 'flex-start',
      flexDirection: orientation === 'vertical' ? 'column' : 'row',
    },
    tabpanel: { p: 4 },
  }),
  variants: {
    line: {
      tablist: { borderBottom: '0.125rem solid', borderColor: 'gray.100' },
      tab: {
        borderBottom: '0.125rem solid',
        borderColor: 'transparent',
        marginBottom: '-0.125rem',
        _selected: { color: 'primary.500', borderColor: 'currentColor' },
        _active: { bg: 'gray.100' },
        _disabled: { opacity: 0.4, cursor: 'not-allowed' },
      },
    },
    solid: {
      tab: {
        borderRadius: 'full',
        bg: 'transparent',
        _hover: { bg: 'gray.50' },
        _focus: { boxShadow: 'none' },
        _focusVisible: {
          outline: '0.125rem solid',
          outlineColor: 'gray.800',
          outlineOffset: '0.125rem',
        },
        _selected: { bg: 'primary.500', color: 'white' },
      },
    },
    contentToolbar: {
      tab: {
        bg: { base: 'gray.900', md: 'gray.50' },
        color: { base: 'gray.200', md: 'gray.500' },
        w: { base: 'auto', md: '72px' },
        h: { base: '80px', md: '56px' },
        flex: 'none',
        '.caret': { opacity: 0 },
        _hover: { color: { base: null, md: 'gray.700' } },
        _focus: { boxShadow: 'none' },
        _focusVisible: {
          bg: { base: 'gray.900', md: 'gray.50' },
          outline: 'none',
          outlineColor: { base: null, md: 'gray.800' },
        },
        _selected: {
          color: { base: 'primary.400', md: 'primary.500' },
          '.caret': { opacity: 1 },
        },
      },
    },
  },
  sizes: {
    sm: { tab: { py: 1, px: 4, fontSize: 'sm' } },
    md: { tab: { fontSize: 'md', py: 2, px: 4 } },
    lg: { tab: { fontSize: 'lg', py: 3, px: 4 } },
  },
};
