import { getExpressionScope, isReactor } from '.';

// TODO: disallow access to anything? Globals?

// const fn = compile('(text1.width - width) / 2')
// const fn = compile('`${text1.x} ${text1.y}`')
export function compile(expression: string, additionalScope?: any): (target?: any) => any {
  const code = new Function('scope', `with (scope) { return (${expression}) }`);
  return function (target: any = {}): any {
    let scope = target;
    if (target && isReactor(target)) {
      scope = target[getExpressionScope]();
    }
    if (additionalScope) {
      // We "merge" together the additional scope (e.g. { event, index, item }) with
      // the target's expression scope (e.g. its properties, its sibling components by name)
      // via prototypal inheritance with an empty "mutableScope" as the root. If the executed
      // formula sets any properties (e.g. x = 0) they are set on the mutable scope.
      const mutableScope: Record<string, any> = {};
      Reflect.setPrototypeOf(mutableScope, additionalScope);
      Reflect.setPrototypeOf(additionalScope, scope);

      const ret = code.call(mutableScope, mutableScope);

      // If a target property change was written to the mutableScope, apply the change
      // to the target. Use "of Object.keys(mutableScope)" instead of "in mutableScope"
      // to only examine the properties of the mutableScope, not its prototypes.
      for (const key of Object.keys(mutableScope)) {
        if (key in target) {
          target[key] = mutableScope[key];
        }
      }
      return ret;
    } else {
      return code.call(scope, scope);
    }
  };
}
