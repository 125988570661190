import { getConfig } from '@playful/config';
import axios, { AxiosRequestConfig } from 'axios';

import { mergeTokenConfig } from './mergeTokenConfig';

// right now we only augment with a token
axios.interceptors.request.use(mergeTokenConfig);

// we would probably be better served using an axios instance in the future with a base config,
// but because of the asynchronous nature of `getConfig`, this should be fine for now.
// `apiRequest` is still around for backwards compatibility, as functions using it may be
// expecting the error to be res.statusText.
export const axiosRequest = (endpoint: string, config: AxiosRequestConfig = {}) =>
  axios({
    // this should be `baseUrl` of an axios instance, but `getConfig` isn't available until
    // `loadConfig` is first called.
    url: !endpoint.startsWith('/') ? `${getConfig().apiRoot}/${endpoint}` : endpoint,
    ...config,
  }).catch((e) => {
    throw e.response;
  });
