import { log } from '../debug';
import type { MigrationId, MigrationRegistry } from '../types';
import { loadMigrations } from './migrationImporter';
import { getProjectMigrator } from './projectMigration';

const knownMigrations = new Set<MigrationId>();
const migrations: MigrationRegistry = {};

loadMigrations({ knownMigrations, migrations });

const availableMigrations = Object.keys(migrations).sort() as MigrationId[];

const { isSafeToRunProject, migrateProjectState } = getProjectMigrator({
  availableMigrations,
  knownMigrations,
  migrations,
});

log({ migrations, knownMigrations });

export { availableMigrations, isSafeToRunProject, migrateProjectState };
