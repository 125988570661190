import { Button as ChButton, IconButton as ChIconButton } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';
import { Link as WLink } from 'wouter';

import type { ButtonProps, IconButtonProps } from './types';

/**
 * Primary UI component for user interaction. See [Button](https://v1.chakra-ui.com/docs/components/form/button) for the complete documentation.
 */
export const Button = forwardRef(({ iconSpacing = 1.5, ...restProps }: ButtonProps, ref) => {
  return <ChButton ref={ref} iconSpacing={iconSpacing} {...restProps} />;
});

export type RouteButtonProps = Omit<ButtonProps, 'href'> & { href: string };

export const RouteButton = forwardRef(
  ({ iconSpacing = 1.5, href, ...restProps }: RouteButtonProps, ref) => {
    return (
      <WLink href={href}>
        <Button ref={ref} {...restProps} />
      </WLink>
    );
  },
);

export const IconButton = forwardRef((props: IconButtonProps, ref) => {
  return <ChIconButton ref={ref} {...props} />;
});
