import { Heading as ChHeading } from '@chakra-ui/layout';
import React from 'react';

import type { HeadingProps } from './types';

/**
 * Base heading component that renders a `<h2 />` by default. See [Heading](https://v1.chakra-ui.com/docs/components/typography/heading) for the complete documentation.
 */
export const Heading = (props: HeadingProps) => {
  return <ChHeading {...props} />;
};
