// This is used to generate slugs for project names
// AND for generating username suggestions from emails
export function slugify(text: string) {
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\p{White_Space}+/gu, '-') // Replace spaces with -
      .replace(/_+/g, '-') // Replace _ with -
      .replace(/\+/g, '') // Remove +
      .replace(/&/g, 'and') // &-> and
      // Remove all non-letters, non-numbers, non-picture emojis, and non-spaces,
      // internationally.
      .replace(
        /[^\-\p{L}\p{N}\p{Extended_Pictographic}\p{Emoji_Modifier}\p{Emoji_Modifier_Base}]+/gu,
        '',
      )
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      // trim beginning and end. use this over `.trim` as trailing `_` turned to `-` will not be removed otherwise.
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  );
}
