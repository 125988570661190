import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const checkboxTheme = {
  defaultProps: { size: 'md', colorScheme: 'primary' },
  parts: ['control', 'icon', 'container', 'label'],
  baseStyle: ({ colorScheme }: StyleFunctionProps) => ({
    icon: {
      transitionProperty: 'transform',
      transitionDuration: 'normal',
    },
    container: {
      _disabled: { cursor: 'not-allowed' },
    },
    control: {
      alignSelf: 'flex-start',
      mt: 1,
      w: '100%',
      transitionProperty: 'box-shadow',
      transitionDuration: 'normal',
      border: '2px solid',
      borderRadius: 'sm',
      borderColor: 'inherit',
      color: 'white',
      _checked: {
        bg: `${colorScheme}.500`,
        borderColor: `${colorScheme}.500`,
        color: 'white',
        _hover: { bg: `${colorScheme}.600`, borderColor: `${colorScheme}.600` },
        _disabled: { borderColor: 'gray.200', bg: 'gray.200', color: 'gray.500' },
      },
      _indeterminate: {
        bg: `${colorScheme}.500`,
        borderColor: `${colorScheme}.500`,
        color: 'white',
      },
      _disabled: { bg: 'gray.100', borderColor: 'gray.100' },
      _focus: {
        outline: '0.125rem solid',
        outlineColor: 'gray.800',
        outlineOffset: '0.125rem',
      },
      _invalid: { borderColor: 'red.500' },
    },
    label: { userSelect: 'none', _disabled: { opacity: 0.4 } },
  }),
  variants: {},
  sizes: {
    sm: { control: { h: 3, w: 3 }, label: { fontSize: 'sm' }, icon: { fontSize: '0.45rem' } },
    md: { control: { w: 4, h: 4 }, label: { fontSize: 'md' }, icon: { fontSize: '0.625rem' } },
    lg: { control: { w: 5, h: 5 }, label: { fontSize: 'lg' }, icon: { fontSize: '0.625rem' } },
  },
};
