import type { TagLabelProps } from '@chakra-ui/react';
import {
  Tag as ChTag,
  TagCloseButton as ChTagCloseButton,
  TagLabel as ChTagLabel,
} from '@chakra-ui/tag';
import React from 'react';

import type { TagCloseButtonProps, TagProps } from './types';

export const Tag = (props: TagProps) => {
  return <ChTag {...props} />;
};

export const TagCloseButton = (props: TagCloseButtonProps) => {
  return <ChTagCloseButton {...props} />;
};

export const TagLabel = (props: TagLabelProps) => {
  return <ChTagLabel {...props} />;
};
