import { Box as ChBox } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';

import type { BoxProps } from './types';

/**
 * Base box component that renders a `<div />` by default. See [Box](https://chakra-ui.com/docs/components/layout/box) for the complete documentation.
 */
export const Box = forwardRef((props: BoxProps, ref) => {
  return <ChBox ref={ref} {...props} />;
});
