import LogRocket from 'logrocket';

import { setUserAttributes } from '../tracing';

//TODO: any -> boolean, but that causes type conflicts between string and boolean
type UserFlags = Record<string, any>;
export type UserProperties = {
  username: string;
  email: string;
  visitor_type: string;
} & UserFlags;

export const rudderIdentify: IdentityFn = (id?: string, userProperties?: UserProperties) => {
  const { rudderanalytics } = window;
  if (!rudderanalytics) {
    return;
  }

  if (!id || !userProperties) {
    rudderanalytics.reset();
  } else {
    rudderanalytics.identify(id, userProperties);
  }
};

export const heapIdentify: IdentityFn = (id?: string, userProperties?: UserProperties) => {
  const { heap } = window;
  if (!heap) {
    return;
  }

  if (!id || !userProperties) {
    heap?.resetIdentity();
    return;
  }

  heap?.identify(id);
  heap?.addUserProperties(userProperties);
};

export const gtagIdentify: IdentityFn = (id?: string, userProperties?: UserProperties) => {
  const { gtag } = window;
  if (!gtag) {
    return;
  }

  if (!id || !userProperties) {
    gtag?.('user_id', null);
    gtag?.('user_properties', null);
    return;
  }

  const gaUserTraits = { userid: id, ...userProperties };

  gtag?.('set', 'user_id', id);
  gtag?.('set', 'user_properties', gaUserTraits);
};

export const logRocketIdentify: IdentityFn = (id?: string, userProperties?: UserProperties) => {
  if (!id || !userProperties) return;

  const { username, ...properties } = userProperties;

  const lrUserTraits = {
    name: username,
    ...properties,
  };

  LogRocket.identify(id, lrUserTraits);
};

export const intercomIdentify: IdentityFn = (id, userProperties) => {
  const { Intercom } = window;
  if (!Intercom) {
    return;
  }

  if (!id || !userProperties) return;

  const intercomUserTraits = {
    user_id: id,
    email: userProperties.email,
    name: userProperties.username,
  };

  Intercom?.('boot', intercomUserTraits);
};

export const tracingIdentify: IdentityFn = (id, userProperties) => {
  setUserAttributes(id, userProperties);
};

export type IdentityFn = (id?: string, userProperties?: UserProperties) => void;

const services = [
  heapIdentify,
  logRocketIdentify,
  gtagIdentify,
  intercomIdentify,
  tracingIdentify,
  rudderIdentify,
];

export function identify(id?: string, userProperties?: UserProperties) {
  services.forEach((identify) => {
    identify(id, userProperties);
  });
}
