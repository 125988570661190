import { Component, forEachObject, selfObjectId } from '@playful/runtime';
import { Interaction, makeVariant } from '@playful/runtime/interactions';
import { generateUUID } from '@playful/runtime/util';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description: 'If the project is pre-cataclysm, convert it to be compatible.',

  migrate(state) {
    const runtimeVersion = state.runtimeVersion || 0;

    // If the project is pre-cataclysm, convert it to be compatible.
    if (runtimeVersion < 8) {
      // Don't have live formulas anymore!
      delete (state as any).liveFormulas;

      function isFormula(expression: any): boolean {
        return typeof expression === 'string' && expression[0] === '=';
      }

      const { Imports, Components } = state;

      // remove these imports
      state.Imports = Imports?.filter(
        ({ name }) =>
          name !== 'Shapes' &&
          name !== 'Location Component' &&
          name !== 'Mouse' &&
          name !== 'Squircle',
      );

      // Remap these imports
      const importMap: Record<string, string> = {
        '6776d23b-b1d2-45ac-8455-382d27d9ab03': 'a089ebea-ce1e-419d-a671-28e71f56d17b', // P5.js
        '0c83d133-54c9-4a49-9c28-a732ca472790': '24ad750f-ffa6-4a7c-86fe-3af453959b0a', // lottie
      };
      state.Imports = state.Imports?.map((imp) => {
        if (imp.projectId && imp.projectId in importMap) {
          return {
            ...imp,
            projectId: importMap[imp.projectId],
            projectVersion: 0,
          };
        } else {
          return imp;
        }
      });

      forEachObject(state, (obj) => {
        if (obj.componentType) {
          const component = obj as Component;

          // Migrate Location
          const locationMatch = obj.componentType.match(/^Location Component\/(.*)/);
          const [, locationName] = locationMatch || [];
          if (locationName) {
            // switch it from Location Component to Play Kit
            obj.componentType = `Play Kit/${locationName}`;
          }

          // Migrate Squircle
          if (obj.componentType === 'Squircle' || obj.componentType === 'Squircle/Squircle') {
            obj.componentType = 'Play Kit/Rectangle';
            obj.cornerRadius =
              obj.radius || Components?.Squircle?._meta?.properties?.radius.default || 25;
            obj.color =
              obj.color || Components?.Squircle?._meta?.properties?.color.default || '#54CB24';
            obj.height =
              obj.height || Components?.Squircle?._meta?.properties?.height.default || 200;
            obj.width = obj.width || Components?.Squircle?._meta?.properties?.width.default || 200;

            delete obj.radius;
            delete obj.smoothing;
          }

          // Fix Lottie name
          if (obj.componentType === 'Lottie Component/Lottie') {
            obj.componentType = 'Lottie/Lottie';
          }

          // Migrate Mouse
          if (obj.componentType === 'Mouse/Mouse') {
            obj.componentType = 'Play Kit/MouseLook';
          }

          // Migrate Shapes
          const shapeMatch = obj.componentType.match(/^Shapes\/(.*)/);
          const [, shapeName] = shapeMatch || [];
          if (shapeName) {
            // switch it from Shapes to Play Kit
            obj.componentType = `Play Kit/${shapeName}`;
          }

          // Migrate Request
          if (obj.componentType === 'Play Kit/Request') {
            if (obj.source) {
              obj.url = obj.source;
              delete obj.source;
            }
            delete obj.refresh;
            delete obj.showResponse;
          }

          for (const property in component) {
            const value = component[property];

            // Convert formulas to into "always: set property" Interactions.
            if (isFormula(value)) {
              const interactions: Interaction[] = (component.interactions =
                component.interactions || []);
              let alwaysInteraction = interactions.find(
                (interaction) => interaction.trigger.event === 'always',
              );
              if (!alwaysInteraction) {
                alwaysInteraction = {
                  trigger: { targetId: selfObjectId, event: 'always' },
                } as Interaction;
                interactions.push(alwaysInteraction);
              }
              if (!alwaysInteraction.actions) {
                alwaysInteraction.actions = [];
              }
              /*console.log(
              `converting ${component.name}.${property} ${value} to "always: set property ${property}" interaction`
            );
            */
              alwaysInteraction.actions.push({
                key: generateUUID(),
                targetId: selfObjectId,
                method: 'setProperty',
                args: {
                  property: makeVariant(property),
                  value: makeVariant(value.slice(1), 'expression'),
                },
              });

              // Remove the formula so the property will be set to its default value.
              // TODO: how does this affect the forEachObject loop?
              delete component[property];
            } else if (isFunction(value) || property === 'eventHandlers') {
              const functions = [];
              if (property !== 'eventHandlers') {
                functions.push({ name: property, fn: value });
              } else {
                for (const handler in value) {
                  functions.push({ name: handler, fn: value[handler] });
                }
              }

              // Delete the function/event handlers.
              delete component[property];

              for (const { name, fn } of functions) {
                // Create a new property to display the function code for reference and so
                // it isn't so mysterious why it's broken.
                const newName = '🦕 ' + name;
                component[newName] = extractScript(fn).script;
                const meta = component._meta || {};
                if (!meta.properties) {
                  meta.properties = {};
                }
                meta.properties[newName] = { type: 'string', editor: { type: 'Script' } };
                component._meta = meta;
              }
            }
          }
        }
      });

      delete Components?.Squircle;
    }

    log(`Migrated ${migration.description}`);
  },
};

export default migration;

function isFunction(value: any): boolean {
  return typeof value === 'string' && value.startsWith('$function');
}
function extractScript(expression: string): { script: string; params: string } {
  const paramsStart = expression.indexOf('(') + 1;
  const paramsEnd = expression.indexOf(')');
  let bodyStart = expression.indexOf('{') + 1;
  if (expression[bodyStart] === '\n') {
    bodyStart++;
  }
  const bodyEnd = expression.lastIndexOf('}');
  const params = expression.slice(paramsStart, paramsEnd).trim();
  const script = expression.slice(bodyStart, bodyEnd);
  return { params, script };
}
