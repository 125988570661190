import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description: 'Remove effects from the project itself',

  /**
   * This PR/Ticket removed the ability to add effects to projects:
   * https://github.com/playful-software/play/issues/2476
   *
   * However, some projects still have lingering effects which we don't want.
   * This migration removes those
   */

  async migrate(state) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore it's not supposed to have effects, that's why we're here
    if (state?.effects) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete state.effects;
    }
    log(`Migrated ${migration.description}`);
  },
};

export default migration;
