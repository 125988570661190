import { extendTheme } from '@chakra-ui/react';

import { formLabelTheme } from '../Form/theme';
import {
  buttonTheme,
  checkboxTheme,
  dividerTheme,
  headingTheme,
  inputTheme,
  linkTheme,
  menuTheme,
  modalTheme,
  numberInputTheme,
  popoverTheme,
  sliderTheme,
  switchTheme,
  tabsTheme,
  tagTheme,
  textTheme,
  tooltipTheme,
} from './components';
import {
  blur,
  borders,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  globalStyles,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
  transition,
  zIndices,
} from './foundations';

/**
 * Theme overrides can be made here, or imported from the individual
 * components.
 *
 */
export const theme = extendTheme({
  direction: 'ltr',
  config: {
    cssVarPrefix: 'play',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },

  blur,
  borders,
  breakpoints,

  colors,

  components: {
    Button: buttonTheme,
    Text: textTheme,
    Heading: headingTheme,
    Tabs: tabsTheme,
    Switch: switchTheme,
    Menu: menuTheme,
    Tooltip: tooltipTheme,
    Popover: popoverTheme,
    Link: linkTheme,
    Divider: dividerTheme,
    Modal: modalTheme,
    Input: inputTheme,
    Slider: sliderTheme,
    Checkbox: checkboxTheme,
    NumberInput: numberInputTheme,
    FormLabel: formLabelTheme,
    Tag: tagTheme,
  },

  fontSizes,
  fontWeights,
  fonts,

  letterSpacings,
  lineHeights,

  radii,
  shadows,
  sizes,
  space,

  styles: {
    global: globalStyles,
  },

  transition,
  zIndices,
});
