import { Component, forEachObject } from '@playful/runtime';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description: 'Migrate effect names from GUID -> componentType',

  migrate(state) {
    const runtimeVersion = state.runtimeVersion || 0;

    // Migrate effect names from GUID -> componentType
    if (runtimeVersion < 17) {
      forEachObject(state, (obj) => {
        if (!obj.componentType || !obj.effects) {
          return;
        }
        // Reset name
        const effects = obj.effects.map((e: Component) => ({ ...e, name: e.componentType }));

        // deduplicate based on name
        const seen = new Set();
        obj.effects = effects.filter((e: Component) => {
          if (seen.has(e.name)) {
            return false;
          } else {
            seen.add(e.name);
            return true;
          }
        });
      });
    }

    log(`Migrated ${migration.description}`);
  },
};

export default migration;
