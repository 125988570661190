import { Modal as ChModal, ModalProps } from '@chakra-ui/react';
import React from 'react';

export function Modal(props: ModalProps) {
  // Chakra uses react-remove-scroll-bar under the cover in some components, which
  // is unfortunate as it has global side-effects and expectations—for instance, it
  // throws an error when body has overflow set to "hidden". so in these circumstances,
  // we can use preserveScrollBarGap to stop the issue.

  // context:
  // https://github.com/chakra-ui/chakra-ui/issues/6213
  // https://github.com/chakra-ui/chakra-ui/issues/5705

  return <ChModal preserveScrollBarGap {...props} />;
}
