export const linkTheme = {
  defaultProps: {},
  parts: [],
  baseStyle: {
    transitionProperty: 'common',
    transitionDuration: 'fast',
    transitionTimingFunction: 'ease-out',
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    _hover: { textDecoration: 'underline' },
    _focusVisible: {
      outline: '0.125rem solid',
      outlineColor: 'gray.800',
      outlineOffset: '0.125rem',
    },
  },
  variants: {
    underline: {
      textDecoration: 'underline',
    },
  },
  sizes: {},
};
