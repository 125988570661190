import { loadProject, migrateProject } from './project';
import type { Project, ProjectInfo, ProjectState } from './runtime';

export class Player {
  project!: Project;

  private console: Console;
  private container: HTMLElement;
  private resizeObserver: ResizeObserver;

  constructor(
    private state: ProjectState,
    private info: ProjectInfo,
    private resourceRoot: string,
    console?: Console,
    containerOrId?: HTMLElement | string,
  ) {
    this.console = console ?? window.console;
    if (typeof containerOrId === 'string') {
      this.container = document.getElementById(containerOrId)!;
    }
    if (containerOrId !== undefined) {
      this.container = containerOrId as HTMLElement;
    } else {
      this.container = document.body;
    }

    // Resize the project as its container resizes.
    // TODO: verify this supports ideal updating on resize

    // Called after layout but before paint.
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === this.container) {
          this.setProjectDimensions(entry.contentRect);
        }
      }
    });
    this.resizeObserver.observe(this.container);
  }

  // Resize project to fill container.
  private setProjectDimensions(rect: DOMRect): void {
    if (!this.project) {
      return;
    }

    const { x, y, width, height } = rect;

    if (
      x !== this.project.viewportX ||
      y !== this.project.viewportY ||
      width !== this.project.viewportWidth ||
      height !== this.project.viewportHeight
    ) {
      this.project.viewportX = x;
      this.project.viewportY = y;
      this.project.viewportWidth = width;
      this.project.viewportHeight = height;
    }
  }

  async asyncInitialize(): Promise<void> {
    await migrateProject(this.state);
    const project = await loadProject(this.state, this.info, undefined, this.resourceRoot);
    this.project = project;
    this.setProjectDimensions(this.container.getBoundingClientRect());

    try {
      project.mount?.(this.container);
    } catch (err) {}
  }

  dispose() {
    this.resizeObserver.disconnect();

    try {
      this.project?.dispose(); // dispose unmounts
    } catch (err) {}
  }
}
