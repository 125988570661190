import {
  Menu as ChMenu,
  MenuButton as ChMenuButton,
  MenuDivider as ChMenuDivider,
  MenuGroup as ChMenuGroup,
  MenuItem as ChMenuItem,
  MenuItemOption as ChMenuItemOption,
  MenuList as ChMenuList,
  MenuOptionGroup as ChMenuOptionGroup,
} from '@chakra-ui/menu';
import { forwardRef } from '@chakra-ui/react';
import React from 'react';

import type {
  MenuButtonProps,
  MenuDividerProps,
  MenuGroupProps,
  MenuItemOptionProps,
  MenuItemProps,
  MenuListProps,
  MenuOptionGroupProps,
  MenuProps,
} from './types';

/**
 * Menu component. See [Menu](https://v1.chakra-ui.com/docs/components/overlay/menu) for the complete documentation.
 */
export function Menu(props: MenuProps) {
  return <ChMenu {...props} />;
}

export const MenuButton = forwardRef((props: MenuButtonProps, ref) => {
  return <ChMenuButton ref={ref} {...props} />;
});

export const MenuGroup = forwardRef((props: MenuGroupProps, ref) => {
  return <ChMenuGroup ref={ref} {...props} />;
});

export const MenuItem = forwardRef((props: MenuItemProps, ref) => {
  return <ChMenuItem ref={ref} {...props} />;
});

export const MenuItemOption = forwardRef((props: MenuItemOptionProps, ref) => {
  return <ChMenuItemOption ref={ref} {...props} />;
});

export const MenuList = forwardRef((props: MenuListProps, ref) => {
  return <ChMenuList ref={ref} {...props} />;
});

export function MenuDivider(props: MenuDividerProps) {
  return <ChMenuDivider {...props} />;
}

export function MenuOptionGroup(props: MenuOptionGroupProps) {
  return <ChMenuOptionGroup {...props} />;
}
