import { trace } from '@opentelemetry/api';
import { hrTime } from '@opentelemetry/core';
import { Metric, getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

const tracer = trace.getTracer('webvitals');

// From https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
function sendToGoogleAnalytics({ name, delta, value, id }: Metric) {
  window.gtag?.('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
  });

  window.rudderanalytics?.track(name, {
    delta,
    value,
    metric_id: id,
  });
}

export function enableWebVitals() {
  const queue: Set<Metric> = new Set();

  const addToQueue = (metric: Metric) => {
    queue.add(metric);
    sendToGoogleAnalytics(metric);
  };
  const flushQueue = () => {
    if (queue.size > 0) {
      const now = hrTime();
      const span = tracer.startSpan('webvitals', { startTime: now });

      queue.forEach((m: Metric) => {
        span.setAttribute(`webvitals.${m.name.toLowerCase()}`, m.value);
      });

      queue.clear();
      span.end(now);
    }
  };

  getCLS(addToQueue);
  getFID(addToQueue);
  getLCP(addToQueue);
  getFCP(addToQueue);
  getTTFB(addToQueue);

  // Report all available metrics whenever the page is backgrounded or unloaded.
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue();
    }
  });

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  addEventListener('pagehide', flushQueue);
}
