import bowser from 'bowser';

// fake for node
let userAgent = 'User-Agent: Mozilla/5.0';
try {
  userAgent = window.navigator.userAgent;
} catch (e) {}

// eslint-disable-next-line import/no-named-as-default-member
const browser = bowser.getParser(userAgent);

export function isMobile(): boolean {
  return browser.getPlatformType() === 'mobile';
}

export function isSafari(): boolean {
  return browser.getBrowserName() === 'Safari';
}

export function isFirefox(): boolean {
  return browser.getBrowserName() === 'Firefox';
}

export function isMac(): boolean {
  return browser.getOSName() === 'macOS';
}

export function isAndroid(): boolean {
  return browser.getOSName() === 'Android';
}

export function isIOS(): boolean {
  return browser.getOSName() === 'iOS';
}

export const isEventSupported = (function () {
  const TAGNAMES: { [key: string]: string } = {
    select: 'input',
    change: 'input',
    submit: 'form',
    reset: 'form',
    error: 'img',
    load: 'img',
    abort: 'img',
  };
  function isEventSupported(eventName: string) {
    const el = document.createElement(TAGNAMES[eventName] || 'div');
    eventName = 'on' + eventName;
    let isSupported = eventName in el;
    if (!isSupported) {
      el.setAttribute(eventName, 'return;');
      isSupported = typeof el[eventName as keyof typeof el] == 'function';
    }
    el?.remove?.();
    return isSupported;
  }
  return isEventSupported;
})();
