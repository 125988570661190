import type { Config } from '@playful/config';
import { isMobile, isSafari } from '@playful/utils';
import { getApp, initializeApp } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  getIdToken,
  inMemoryPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';

export function initFirebase(cfg: Config) {
  // Firebase app config
  const config = {
    apiKey: cfg.firebaseApiKey,
    authDomain: cfg.firebaseAuthDomain,
    databaseURL: cfg.firebaseDatabaseUrl,
    projectId: cfg.googleProjectId,
    messagingSenderId: cfg.firebaseSenderId,
    appId: cfg.firebaseAppId,
  };

  initializeApp(config);
}

export async function getFirebaseAuthToken(): Promise<string | undefined> {
  const auth = getAuth();
  if (auth.currentUser === null) {
    return undefined;
  }
  return await getIdToken(auth.currentUser);
}

let auth: Auth | undefined;

// Define some auth 'presets'
// https://firebase.google.com/docs/auth/web/custom-dependencies

// Normal auth
export function getAuth(): Auth {
  let popupRedirectResolver;

  // safari *desktop* needs popupRedirectResolver pre-loaded so that popups can work
  if (isSafari() && !isMobile()) {
    popupRedirectResolver = browserPopupRedirectResolver;
  }

  if (!auth) {
    auth = initializeAuth(getApp(), {
      persistence: [indexedDBLocalPersistence, browserLocalPersistence], //default
      popupRedirectResolver,
    });
  }
  return auth;
}

// Auth without the need to sign in
export function getSimpleAuth(): Auth {
  if (!auth) {
    auth = initializeAuth(getApp(), {
      persistence: [indexedDBLocalPersistence, browserLocalPersistence], //default
      // No popupRedirectResolver defined
    });
  }
  return auth;
}

// Auth when we use a custom token, uses inMemoryPersistence which basically means 'none'
export function getTemporaryAuth(): Auth {
  if (!auth) {
    auth = initializeAuth(getApp(), {
      persistence: [inMemoryPersistence],
      // No popupRedirectResolver defined
    });
  }
  return auth;
}
