import { getConfig } from '@playful/config';

import { mergeTokenConfig } from './mergeTokenConfig';

export async function apiRequest(endpoint: string, config = {}): Promise<any> {
  const mergedConfig = await mergeTokenConfig(config);

  // relative endpoints are relative to the apiRoot
  if (!endpoint.startsWith('/')) {
    endpoint = `${getConfig().apiRoot}/${endpoint}`;
  }
  const res = await fetch(endpoint, mergedConfig);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}
