import { forEachObject } from '@playful/runtime';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  description: 'Migrate mixins -> effects',

  migrate(state) {
    const runtimeVersion = state.runtimeVersion || 0;

    // Migrate mixins -> effects
    if (runtimeVersion < 16) {
      forEachObject(state, (obj) => {
        if (!obj.componentType || !obj.mixins) {
          return;
        }
        obj.effects = obj.mixins || [];
        delete obj.mixins;
      });
    }

    log(`Migrated ${migration.description}`);
  },
};

export default migration;
