import type { RemoteClientRpc } from './types';

/**
 * Event Serialization
 *
 * We attempt to take every standard serializable property from the event types
 * we've chosen to proxy over the wire and reconstitute it. We have chosen to
 * defer any attempt to implement the methods on the event, with the exception
 * of the telemetry shims we'll put on the common stopPropagation and preventDefault.
 *
 * At some point (especially with some of the serializations that have a bit of
 * work to them) we might want to keep track of which events a component cares
 * about and only send over the wire those events that at least one things cares
 * about?
 */
type SerializedFocusEvent = {
  eventClass: 'focus';
  eventType: EventType;

  detail: number;
};

type SerializedTextInputEvent = {
  eventClass: 'textinput';
  eventType: EventType;

  data: InputEvent['data'];
  inputType: InputEvent['inputType'];
  isComposing: InputEvent['isComposing'];
};

type SerializedInputEvent = {
  eventClass: 'input';
  eventType: EventType;
};

type SerializedKeyboardEvent = {
  eventClass: 'keyboard';
  eventType: EventType;

  altKey: KeyboardEvent['altKey'];
  code: KeyboardEvent['code'];
  ctrlKey: KeyboardEvent['ctrlKey'];
  isComposing: KeyboardEvent['isComposing'];
  key: KeyboardEvent['key'];
  location: KeyboardEvent['location'];
  metaKey: KeyboardEvent['metaKey'];
  repeat: KeyboardEvent['repeat'];
  shiftKey: KeyboardEvent['shiftKey'];
};

type SerializedPointerEvent = {
  eventClass: 'pointer';
  eventType: EventType;

  altKey: PointerEvent['altKey'];
  button: PointerEvent['button'];
  buttons: PointerEvent['buttons'];
  clientX: PointerEvent['clientX'];
  clientY: PointerEvent['clientY'];
  ctrlKey: PointerEvent['ctrlKey'];
  height: PointerEvent['height'];
  isPrimary: PointerEvent['isPrimary'];
  metaKey: PointerEvent['metaKey'];
  movementX: PointerEvent['movementX'];
  movementY: PointerEvent['movementY'];
  offsetX: PointerEvent['offsetX'];
  offsetY: PointerEvent['offsetY'];
  pageX: PointerEvent['pageX'];
  pageY: PointerEvent['pageY'];
  pointerId: PointerEvent['pointerId'];
  pointerType: PointerEvent['pointerType'];
  pressure: PointerEvent['pressure'];
  screenX: PointerEvent['screenX'];
  screenY: PointerEvent['screenY'];
  shiftKey: PointerEvent['shiftKey'];
  tangentialPressure: PointerEvent['tangentialPressure'];
  tiltX: PointerEvent['tiltX'];
  tiltY: PointerEvent['tiltY'];
  twist: PointerEvent['twist'];
  width: PointerEvent['width'];
  x: PointerEvent['x'];
  y: PointerEvent['y'];
};

type SerializedTouch = {
  clientX: Touch['clientX'];
  clientY: Touch['clientY'];
  identifier: Touch['identifier'];
  pageX: Touch['pageX'];
  pageY: Touch['pageY'];
  screenX: Touch['screenX'];
  screenY: Touch['screenY'];
};

type SerializedTouchList = Array<SerializedTouch>;

type SerializedTouchEvent = {
  eventClass: 'touch';
  eventType: EventType;

  altKey: TouchEvent['altKey'];
  changedTouches: SerializedTouchList;
  ctrlKey: TouchEvent['ctrlKey'];
  metaKey: TouchEvent['metaKey'];
  shiftKey: TouchEvent['shiftKey'];
  targetTouches: SerializedTouchList;
  touches: SerializedTouchList;
};

type SerializedWheelEvent = {
  eventClass: 'wheel';
  eventType: EventType;

  deltaMode: WheelEvent['deltaMode'];
  deltaX: WheelEvent['deltaX'];
  deltaY: WheelEvent['deltaY'];
  deltaZ: WheelEvent['deltaZ'];
};

type SerializedEvent =
  | SerializedFocusEvent
  | SerializedTextInputEvent
  | SerializedInputEvent
  | SerializedKeyboardEvent
  | SerializedPointerEvent
  | SerializedTouchEvent
  | SerializedWheelEvent;

type EventClass = SerializedEvent['eventClass'];
type EventType = typeof events[number];

const events = [
  'auxclick',
  'click',
  'dblclick',
  'focusin',
  'focusout',
  'input',
  'textinput',
  'keydown',
  'keypress',
  'keyup',
  'pointerdown',
  'pointermove',
  'pointerout',
  'pointerover',
  'pointerup',
  'touchend',
  'touchmove',
  'touchstart',
  'wheel',
] as const;

let dispose: () => void | undefined;

export function attachEventListeners(self: EventTarget, play: RemoteClientRpc) {
  dispose?.();

  const listeners: Array<[EventTarget, string, EventListener]> = [];

  for (const type of events) {
    const handler: EventListener = (event) => {
      play.dispatchEvent(serializeEvent(event));
    };

    self.addEventListener(type, handler);
    listeners.push([self, type, handler]);
  }

  dispose = () => {
    for (const [self, type, listener] of listeners) {
      self.removeEventListener(type, listener);
    }
  };
}

export function isPlaySerializedEvent(event: any): event is SerializedEvent {
  return event && event.eventClass && event.eventType;
}

export function serializeEvent(event: Event): SerializedEvent {
  const core: { eventType: SerializedEvent['eventType'] } & EventInit = {
    eventType: event.type as EventType,

    bubbles: event.bubbles,
    cancelable: event.cancelable,
  };

  if (event instanceof FocusEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      detail: event.detail,
    } as SerializedFocusEvent;
  }

  if (event instanceof InputEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      data: event.data,
      inputType: event.inputType,
      isComposing: event.isComposing,
    } as SerializedTextInputEvent;
  }

  // How is InputEvent different from event.type === 'input'? Learn something new every day:
  // https://stackoverflow.com/questions/63273548/why-input-event-has-event-type-of-event-instead-of-inputevent
  if (event.type === 'input') {
    return {
      eventClass: getEventClass(event),
      ...core,
    } as SerializedInputEvent;
  }

  if (event instanceof KeyboardEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      altKey: event.altKey,
      code: event.code,
      ctrlKey: event.ctrlKey,
      isComposing: event.isComposing,
      key: event.key,
      location: event.location,
      metaKey: event.metaKey,
      repeat: event.repeat,
      shiftKey: event.shiftKey,
    } as SerializedKeyboardEvent;
  }

  if (event instanceof MouseEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      altKey: event.altKey,
      button: event.button,
      buttons: event.buttons,
      clientX: event.clientX,
      clientY: event.clientY,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
      movementX: event.movementX,
      movementY: event.movementY,
      offsetX: event.offsetX,
      offsetY: event.offsetY,
      pageX: event.pageX,
      pageY: event.pageY,
      screenX: event.screenX,
      screenY: event.screenY,
      shiftKey: event.shiftKey,
      x: event.x,
      y: event.y,
    } as SerializedPointerEvent;
  }

  if (event instanceof PointerEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      altKey: event.altKey,
      button: event.button,
      buttons: event.buttons,
      clientX: event.clientX,
      clientY: event.clientY,
      ctrlKey: event.ctrlKey,
      height: event.height,
      isPrimary: event.isPrimary,
      metaKey: event.metaKey,
      movementX: event.movementX,
      movementY: event.movementY,
      offsetX: event.offsetX,
      offsetY: event.offsetY,
      pageX: event.pageX,
      pageY: event.pageY,
      pointerId: event.pointerId,
      pointerType: event.pointerType,
      pressure: event.pressure,
      screenX: event.screenX,
      screenY: event.screenY,
      shiftKey: event.shiftKey,
      tangentialPressure: event.tangentialPressure,
      tiltX: event.tiltX,
      tiltY: event.tiltY,
      twist: event.twist,
      width: event.width,
      x: event.x,
      y: event.y,
    } as SerializedPointerEvent;
  }

  if (
    // thanks safari :-(
    typeof TouchEvent !== 'undefined' &&
    event instanceof TouchEvent
  ) {
    const touchEvent = event as TouchEvent;
    return {
      eventClass: getEventClass(touchEvent),
      ...core,

      altKey: touchEvent.altKey,
      changedTouches: serializeTouchList(touchEvent.changedTouches),
      ctrlKey: touchEvent.ctrlKey,
      metaKey: touchEvent.metaKey,
      shiftKey: touchEvent.shiftKey,

      targetTouches: serializeTouchList(touchEvent.targetTouches),
      touches: serializeTouchList(touchEvent.touches),
    } as SerializedTouchEvent;
  }

  if (event instanceof WheelEvent) {
    return {
      eventClass: getEventClass(event),
      ...core,

      deltaMode: event.deltaMode,
      deltaX: event.deltaX,
      deltaY: event.deltaY,
      deltaZ: event.deltaZ,
    } as SerializedWheelEvent;
  }

  throw new Error(`unsupported event type ${event.type}`);
}

export function deserializeEvent(sandbox: EventTarget, serializedEvent: SerializedEvent): Event {
  switch (serializedEvent.eventClass) {
    case 'focus': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new FocusEvent(type, initDict);
    }
    case 'textinput': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new InputEvent(type, initDict);
    }
    case 'input': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new Event(type, initDict);
    }
    case 'keyboard': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new KeyboardEvent(type, initDict);
    }
    case 'pointer': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new PointerEvent(type, initDict);
    }
    case 'touch': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      function setTarget(serializedTouch: SerializedTouch): Touch {
        return new Touch({
          ...serializedTouch,
          target: sandbox,
        });
      }

      const dict: TouchEventInit = {
        ...initDict,

        changedTouches: initDict.changedTouches.map(setTarget),
        targetTouches: initDict.targetTouches.map(setTarget),
        touches: initDict.touches.map(setTarget),
      };

      if (typeof TouchEvent === 'undefined') {
        // thanks safari :-(
        return new CustomEvent(type, dict as any);
      }
      return new TouchEvent(type, dict);
    }
    case 'wheel': {
      const { eventClass: _, eventType: type, ...initDict } = serializedEvent;

      return new WheelEvent(type, initDict);
    }
  }

  throw new Error(`unsupported event type ${(serializedEvent as SerializedEvent).eventClass}`);
}

function getEventClass(event: Event): EventClass {
  if (event instanceof FocusEvent) {
    return 'focus';
  }
  if (event instanceof InputEvent) {
    return 'textinput';
  }
  if (event.type === 'input') {
    return 'input';
  }
  if (event instanceof KeyboardEvent) {
    return 'keyboard';
  }
  if (event instanceof PointerEvent || event instanceof MouseEvent) {
    return 'pointer';
  }
  if (
    // thanks safari :-(
    typeof TouchEvent !== 'undefined' &&
    event instanceof TouchEvent
  ) {
    return 'touch';
  }
  if (event instanceof WheelEvent) {
    return 'wheel';
  }

  throw new Error(`unsupported event type ${event.type}`);
}

export function serializeTouchList(touchList: TouchList): SerializedTouchList {
  const serialized: SerializedTouchList = [];

  for (let i = 0; i < touchList.length; i++) {
    const touch = touchList.item(i);

    if (!touch) {
      continue;
    }

    serialized.push({
      clientX: touch.clientX,
      clientY: touch.clientY,
      identifier: touch.identifier,
      pageX: touch.pageX,
      pageY: touch.pageY,
      screenX: touch.screenX,
      screenY: touch.screenY,
    });
  }

  return serialized;
}
