import type { ProjectState } from '@playful/runtime';

import { log, warn } from '../debug';
import type { MigrationId, MigrationRegistry } from '../types';

export type MigratorRunSafeCheckErrors = Array<{ message: string }>;

export const getProjectMigrator = ({
  availableMigrations,
  knownMigrations,
  migrations,
}: {
  availableMigrations: MigrationId[];
  knownMigrations: Set<MigrationId>;
  migrations: MigrationRegistry;
}) => ({
  async migrateProjectState(state: ProjectState): Promise<boolean> {
    if (!state.appliedMigrations) {
      state.appliedMigrations = [];
    }

    const appliedMigrations = new Set(state.appliedMigrations || []);

    for (const migrationId of availableMigrations) {
      if (!appliedMigrations.has(migrationId)) {
        const migration = migrations[migrationId];

        log('migrateProject', migrationId);

        try {
          await migration.migrate(state);
        } catch (e) {
          console.error('Migrator: Error: ', e);
          throw e;
        }

        if (!migration.draft) {
          appliedMigrations.add(migrationId);
        }

        log('applied', migrationId);
      }
    }

    for (const migrationId of Object.keys(appliedMigrations) as MigrationId[]) {
      if (!migrations[migrationId] && knownMigrations.has(migrationId)) {
        warn(`removing retired migration ${migrationId}`);

        appliedMigrations.delete(migrationId);
      }
    }

    state.appliedMigrations = [...appliedMigrations];

    return true;
  },

  isSafeToRunProject(state: ProjectState, errors?: MigratorRunSafeCheckErrors): boolean {
    if (!state.appliedMigrations) {
      return false;
    }

    for (const migrationId of state.appliedMigrations) {
      if (!knownMigrations.has(migrationId)) {
        errors?.push({ message: `migration ${migrationId} not found` });
        // migration from the future
        return false;
      }
    }

    return true;
  },
});
