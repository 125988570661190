import type { ModalContentProps } from '@chakra-ui/react';
import React from 'react';

import { Box } from '../Box';

type Position = 'top' | 'bottom';

export function FlexModalContent({
  children,
  modalPos: pos,
  ...props
}: ModalContentProps & { modalPos?: Position }) {
  const isTopAligned = pos === 'top';
  const isBottomAligned = pos === 'bottom';

  // alignment
  const alignItems = isBottomAligned ? 'flex-end' : isTopAligned ? 'flex-start' : 'center';

  return (
    <Box
      position='fixed'
      display='flex'
      justifyContent='center'
      alignItems={alignItems}
      w='100vw'
      h='full'
      zIndex='modal'
      left={0}
      top={0}
    >
      <Box
        m={16}
        maxH='full'
        display='flex'
        flexDirection='column'
        position='relative'
        width='full'
        outline='2px solid transparent'
        outlineOffset='2px'
        borderRadius='md'
        background='white'
        color='inherit'
        zIndex='modal'
        boxShadow='lg'
        maxW='md'
        overflow='auto'
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
}
