import { getConfig } from '@playful/config';

import { getFirebaseAuthToken } from './firebase';

/**
 * Upload an ArrayBuffer to a URL
 * @param uploadUrl
 * @param buf
 * @param mimeType
 * @param onProgress
 */
export async function uploadFile(
  uploadUrl: string,
  buf: ArrayBuffer | Blob,
  mimeType?: string,
  onProgress?: (progress: number) => any,
): Promise<any> {
  const xhr = new XMLHttpRequest();
  // relative endpoints are relative to the apiRoot
  if (!uploadUrl.startsWith('/')) {
    uploadUrl = `${getConfig().apiRoot}/${uploadUrl}`;
  }
  xhr.open('PUT', uploadUrl, true);
  xhr.setRequestHeader('Content-Type', mimeType ?? 'application/octet-stream');

  // Add auth token
  const token = await getFirebaseAuthToken();
  if (token) {
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  const promise = new Promise((resolve, reject) => {
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        const json = JSON.parse(xhr.responseText);
        resolve(json);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
  });

  if (onProgress) {
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress((e.loaded / e.total) * 100);
      }
    };
  }
  xhr.send(buf);
  return promise;
}
