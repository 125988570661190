import { loadProject, migrateProject } from './project';
import { deserialize } from './reactor';
import type { ImportInfo, Project, ProjectState } from './runtime';
import { dirName, pathJoin } from './util';

export function getImportProtocolAndPath(importInfo: ImportInfo): string[] {
  if (!importInfo.source) {
    return ['', ''];
  }
  const s = importInfo.source.split(':');
  return [s[0], s.slice(1).join(':')];
}

export async function importProjectOrModule(
  importInfo: ImportInfo,
  project: Project,
): Promise<any> {
  const imports = project.imports;

  // This allows unit tests to 'shim' the import
  if (importInfo.loader) {
    return importInfo
      .loader()
      .then((module: any) => {
        imports[importInfo.name] = module;
      })
      .catch((err) => console.error(err));
  }

  const [protocol, path] = getImportProtocolAndPath(importInfo);
  if (!protocol) {
    return;
  }

  //console.log(`import ${importInfo.name} (${importInfo.source})`);
  switch (protocol) {
    // Handle playkit imports
    case 'playkit': {
      // Import!
      // eslint-disable-next-line import/no-restricted-paths
      return import('@playful/playkit')
        .then((module: any) => {
          imports[importInfo.name] = module;
        })
        .catch((err) => console.error(err));
    }

    // Import components from subproject within this project's resource
    case 'project': {
      const url = pathJoin([project.resourceRoot, path]);
      const resourceRoot = pathJoin([project.resourceRoot, dirName(path)]);

      return readRemoteProject(url)
        .then((state) => importProjectState(state, importInfo, project, resourceRoot))
        .catch((err) => {
          // imp.error = err;
          console.error(err);
        });
    }

    default:
      console.error(`unknown import protocol: ${importInfo.source}`);
  }
}

function importProjectState(
  state: ProjectState,
  importInfo: ImportInfo,
  project: Project,
  resourceRoot: string,
): Promise<void> {
  const imports = project.imports;
  return loadProject(
    state,
    { title: importInfo.name } as any,
    project.mainProject,
    resourceRoot,
  ).then((importedProject) => {
    imports[importInfo.name] = importedProject;
  });
}

export function unimportProjectOrModule(imp: ImportInfo, project: Project): void {
  delete project.imports[imp.name];
}

export function importProjectsAndModules(project: Project): Promise<any>[] {
  const Imports = project.Imports!;
  if (!Imports) {
    return [];
  }

  return Imports.map((info) => importProjectOrModule(info, project));
}

// TODO: progress
export async function readRemoteProject(url: string): Promise<ProjectState> {
  // TODO: error handling
  const response = await fetch(url);
  // TODO: error handling
  const jsonText = await response.text();
  const json = deserialize(jsonText);

  // Upgrade the project to the latest format.
  await migrateProject(json);

  return json;
}
