import { context, trace } from '@opentelemetry/api';
import { shallowEqual } from '@playful/utils';

import { INITIAL_STATE, initComponent, isComponent } from './component';
import {
  importProjectOrModule,
  importProjectsAndModules,
  unimportProjectOrModule,
} from './importer';
import { isSafeToRunProject, migrateProjectState } from './migrator';
import type { MigratorRunSafeCheckErrors } from './migrator/migrations/projectMigration';
import { ID, Reactor, ReactorId, parseComponentType } from './reactor';
import { createReactorFactory } from './reactorFactory';
import { setReactorStoredProperty } from './reactorObject';
import type { ImportInfo, Project, ProjectInfo, ProjectState } from './runtime';
import { isObjectNotArray, isObjectOrArray } from './util';
import type { Component, Properties } from '.';

type Writable<T> = { -readonly [K in keyof T]: T[K] };

const tracer = trace.getTracer('runtime');

export async function loadProject(
  state: ProjectState,
  info?: ProjectInfo,
  mainProject?: Project,
  resourceRoot?: string,
): Promise<Project> {
  const span = tracer.startSpan('loadProject');
  info?.id && span.setAttribute('project.id', info?.id);
  info?.title && span.setAttribute('project.title', info?.title);

  const ctx = trace.setSpan(context.active(), span);

  // Calculate the max in-use reactor id
  let maxReactorId = 0;
  forEachObject(state, (obj) => {
    if (obj[ID] > maxReactorId) {
      maxReactorId = obj[ID];
    }
  });

  const reactorFactory = createReactorFactory(maxReactorId + 1);

  const project = reactorFactory.createReactor<Project>({
    Imports: [],
    ...state,
    pages: undefined,
    Components: undefined,
    style: undefined,
  });

  // Setup project-level functions
  project.createReactor = reactorFactory.createReactor.bind(reactorFactory);
  project.getReactorById = reactorFactory.getReactorById.bind(reactorFactory);
  project.hasReactor = reactorFactory.hasReactor.bind(reactorFactory);
  project.forEachReactor = reactorFactory.forEachReactor.bind(reactorFactory);
  project.createComponent = (state) => createComponent(project, state);
  project.getComponentById = (id: ReactorId) => reactorFactory.getReactorById(id) as Component;

  // Initialize Project imports
  project.imports = {};

  // All projects have a reference to the main (root/host/global/etc) project.
  project.mainProject = mainProject || project;
  project.resourceRoot = resourceRoot || mainProject?.resourceRoot || '';

  // TODO:
  if (info) {
    // Funky cast to override readonly.
    (project as Writable<Project>).info = { ...info }; // TODO: not updated as info changes (e.g. after Project save)
  }

  // Wait for all imports before updating Reactor prototypes which may come from them.
  await context.with(ctx, () => {
    return Promise.allSettled(importProjectsAndModules(project));
  });

  project.onPropertyChange(async (reactor, property, newValue, oldValue, type) => {
    property = property as string;
    if (property === 'Imports') {
      processImportChange(project, newValue, oldValue);
    }
  });

  // Init the project component (has to be done after Imports so that we can find Play Kit)
  initComponent(project, project);

  // Setup Components array
  project[setReactorStoredProperty]('Components', project.createReactor({}));
  for (const name in state.Components) {
    const comp = createComponent(project, state.Components[name]);
    project.Components[setReactorStoredProperty](name, comp);
  }

  // TODO: It's annoying to have to do this stuff here. Find a better way.
  // Setup pages
  project[setReactorStoredProperty](
    'pages',
    project.createReactor(state.pages?.map(project.createComponent) || []),
  );

  // Style
  if (state.style) {
    project[setReactorStoredProperty]('style', project.createComponent(state.style));
  }

  span.end();
  return project;
}

/**
 * Create (and init) a component from a bag of properties
 *
 * This also recurses and creates any child components.
 */
function createComponent<T extends Component>(project: Project, properties: Properties): T {
  const component = project.createReactor<T>({ [ID]: properties[ID] });
  component[INITIAL_STATE] = properties;

  for (const property in properties) {
    let value = properties[property];
    // TODO: remove this hack
    if (['children', 'pages'].includes(property)) {
      value = project.createReactor(value.map((v: Properties) => createComponent(project, v)));
    } else if (Array.isArray(value)) {
      // Look for components in arrays
      value = value.map((v: Properties) => (v.componentType ? createComponent(project, v) : v));
    } else if (isObjectNotArray(value)) {
      if (value.componentType) {
        value = createComponent(project, value);
      }
    }
    component[setReactorStoredProperty](property, value);
  }

  initComponent(component, project);
  return component;
}

async function processImportChange(
  project: Project,
  newValue: ImportInfo[],
  oldValue: ImportInfo[],
) {
  const newImports = newValue.reduce<Record<string, ImportInfo>>(
    (obj, cur) => ({ ...obj, [cur.name]: cur }),
    {},
  );
  const oldImports = oldValue.reduce<Record<string, ImportInfo>>(
    (obj, cur) => ({ ...obj, [cur.name]: cur }),
    {},
  );
  const allImportNames = new Set([...Object.keys(newImports), ...Object.keys(oldImports)]);
  const changedImports = new Set();

  // Diff the imports and take action
  for (const name of allImportNames) {
    const newImp = newImports[name];
    const oldImp = oldImports[name];
    if (newImp === undefined) {
      // Remove the import
      unimportProjectOrModule(oldImp, project);
      changedImports.add(name);
    } else if (!shallowEqual(newImp, oldImp)) {
      // Added or changed the import
      await importProjectOrModule(newImp, project);
      changedImports.add(name);
    }
  }

  // Re-init components from any change imports
  project.forEachReactor((component: Reactor) => {
    if (isComponent(component)) {
      const { importPath } = parseComponentType(component.componentType);
      if (changedImports.has(importPath)) {
        initComponent(component, project);
      }
    }
    return true;
  });
}

// migrateProject alters the ProjectState in place.
export async function migrateProject(state: ProjectState): Promise<void> {
  await migrateProjectState(state);
  const errors: MigratorRunSafeCheckErrors = [];
  if (!isSafeToRunProject(state, errors)) {
    throw new Error(
      `Unable to load project. It may have migrations applied to it that are known to another branch. ${JSON.stringify(
        errors,
      )}`,
    );
  }
}

// Iterate and recurse down an (acyclic) object hierarchy, calling back for each one.
export function forEachObject(obj: any, callback: (obj: any) => void): void {
  if (Array.isArray(obj)) {
    for (const element of obj) {
      if (isObjectOrArray(element)) {
        forEachObject(element, callback);
      }
    }
  } else {
    for (const property in obj) {
      const value = obj[property];
      if (isObjectOrArray(value)) {
        forEachObject(value, callback);
      }
    }
  }
  callback(obj);
}

// TODO: DRY with other isProject
export function isProject(component: any): component is Project {
  return component.mainProject && component.mainProject === component;
}
