import { Text as ChText } from '@chakra-ui/layout';
import React from 'react';

import type { TextProps } from './types';

/**
 * Base text component that renders a `<p />` by default. See [Text](https://v1.chakra-ui.com/docs/components/typography/text) for the complete documentation.
 */
export const Text = (props: TextProps) => {
  return <ChText {...props} />;
};
