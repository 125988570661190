import { apiRequest } from './apiRequest';
import { fetchRetry } from './fetchRetry';

export async function apiRequestWithRetry(endpoint: string, config = {}, numberOfTries = 5) {
  async function request() {
    return apiRequest(endpoint, config);
  }
  const res = await fetchRetry(request, numberOfTries);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}
