import { forEachObject } from '@playful/runtime';

import { log } from '../debug';
import type { Migration } from '../types';

const migration: Migration = {
  draft: false,
  description:
    'Changes existing Text components to be `fixed` while defaulting new ones to Dynamic',

  async migrate(state) {
    /**
     * Projects have relied on fixed sized text Components since their inception.
     * Now Text components default to `dynamic`.
     * This migration sets the new sizeMode property to `fixed` for existing components
     * so that existing projects are not re-arranged.
     */

    forEachObject(state, (obj) => {
      if (
        !['Play Kit/Text', 'Play Kit/Subheading', 'Play Kit/Heading', 'Play Kit/BodyText'].includes(
          obj.componentType,
        )
      ) {
        return;
      }

      if (!obj.sizeMode) {
        obj.sizeMode = 'fixed';
      }
    });

    log(`Migrated ${migration.description}`);
  },
};

export default migration;
