import { Divider as ChDivider } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';

import type { DividerProps } from './types';

/**
 * Divider component that renders an `<hr />` by default. See [Box](https://v1.chakra-ui.com/docs/components/data-display/divider) for the complete documentation.
 */
export const Divider = forwardRef((props: DividerProps, ref) => {
  return <ChDivider ref={ref} {...props} />;
});
