// Don't patch these for performance reasons
if (typeof window !== 'undefined') {
  const cfg = window as any;
  cfg.__Zone_disable_timers = true;
  cfg.__Zone_disable_requestAnimationFrame = true;
  cfg.__Zone_disable_MutationObserver = true;
  cfg.__Zone_disable_IntersectionObserver = true;
  cfg.__Zone_disable_geolocation = true;
  cfg.__Zone_disable_canvas = true;
  cfg.__Zone_disable_on_property = true;
  cfg.__Zone_disable_customElements = true;
  cfg.__Zone_disable_FileReader = true;
  cfg.__Zone_disable_queueMicrotask = true;

  cfg.__zone_symbol__UNPATCHED_EVENTS = [
    'blur',
    'drag',
    'dragend',
    'dragenter',
    'dragleave',
    'dragstart',
    'drop',
    'focus',
    'focusin',
    'focusout',
    'keydown',
    'keyup',
    'mousemove',
    'pointercancel',
    'pointerdown',
    'pointerenter',
    'pointerleave',
    'pointermove',
    'pointerout',
    'pointerover',
    'pointerup',
    'resize',
    'scroll',
    'touchcancel',
    'touchend',
    'touchmove',
    'touchstart',
    'wheel',
  ];
}
