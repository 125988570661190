import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { forwardRef } from '@chakra-ui/react';
import React, { useState } from 'react';

import { IconButton } from '../Button';
import { Input, InputGroup, InputRightElement } from './Input';
import type { InputProps } from './types';

export const PasswordInput = forwardRef((props: InputProps, ref) => {
  const [showPw, setShowPw] = useState(false);

  return (
    <InputGroup>
      <Input ref={ref} type={showPw ? 'text' : 'password'} {...props} />
      {!props.isDisabled && (
        <InputRightElement>
          <IconButton
            variant='ghost'
            isRound
            aria-label='Show Password'
            size='sm'
            onClick={() => setShowPw(!showPw)}
            icon={showPw ? <ViewIcon /> : <ViewOffIcon />}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
});
