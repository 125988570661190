import LogRocket from 'logrocket';

export function initializeLogRocket(id: string) {
  if (id && !(window as any).Cypress)
    LogRocket.init(id, {
      console: {
        // LogRocket needs this setting to record 'issues' for errors
        shouldAggregateConsoleErrors: true,
      },
    });
}
