import type { Span, SpanProcessor } from '@opentelemetry/sdk-trace-base';

import type { UserProperties } from '../events/identify';

// A SpanProcessor to add current url to every event
export class SpanAttributesProcessor implements SpanProcessor {
  _userId?: string;
  _userProps?: UserProperties;

  setUserAttributes(id?: string, props?: UserProperties): void {
    this._userId = id;
    this._userProps = props;
  }

  forceFlush(): Promise<void> {
    return Promise.resolve();
  }

  onStart(span: Span): void {
    // Filter out auth param
    const url = new URL(location.href);
    if (url.searchParams.has('auth')) {
      url.searchParams.set('auth', 'REDACTED');
    }
    span.setAttribute('location.href', url.toString());
    this._userId && span.setAttribute('user.id', this._userId);
    if (this._userProps) {
      for (const [k, v] of Object.entries(this._userProps)) {
        span.setAttribute(`user.${k}`, v);
      }
    }
  }

  onEnd(): void {
    // empty
  }

  shutdown(): Promise<void> {
    return Promise.resolve();
  }
}
