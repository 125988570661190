import { useEffect, useRef, useState } from 'react';

import type { IProjectModel, Selection } from '../designRuntime';

/**
 * Provide a simple bridge between the projectModel event listener style and reactor statey style
 * for subscribing to current selection
 */
export function useSelection(
  projectModel: IProjectModel,
  callback?: (event: { readonly selection: Selection }) => void,
): Selection {
  const [selection, setSelection] = useState<Selection>(projectModel.getSelection());
  // Tracks if the component that called this hook is still mounted before updating the selection state.
  const componentMounted = useRef(true);
  useEffect(() => {
    componentMounted.current = true;
    const disposable = projectModel.onChangeSelection(({ selection }) => {
      if (componentMounted.current) {
        setSelection(selection);
        callback?.({ selection });
      }
    });
    return () => {
      componentMounted.current = false;
      disposable.dispose();
    };
  }, [callback, projectModel]);
  return selection;
}
