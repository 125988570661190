import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const buttonTheme = {
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'gray',
  },
  parts: [],
  variants: {
    pill: ({ colorScheme }: StyleFunctionProps) => {
      return {
        backgroundColor: 'transparent',
        color: `${colorScheme}.700`,
        _hover: {
          backgroundColor: `${colorScheme}.500`,
          _disabled: { bg: 'transparent' },
          color: 'white',
          '& svg': {
            color: 'white',
            borderColor: 'white',
          },
        },
        _active: {
          backgroundColor: `${colorScheme}.700`,
          color: 'white',
          '& svg': {
            color: 'white',
            borderColor: 'white',
          },
        },
      };
    },
    ghost: ({ colorScheme }: StyleFunctionProps) => {
      const bgStart = 'transparent';
      const bgHover = `${colorScheme}.400`;
      const bgActive = `${colorScheme}.50`;
      const textColor = `${colorScheme}.700`;

      return {
        bg: bgStart,
        color: textColor,
        _hover: {
          bg: bgHover,
          _disabled: { bg: bgStart },
          color: `${colorScheme}.900`,
        },
        _active: { bg: bgActive, color: `${colorScheme}.900` },
      };
    },
    outline: ({ colorScheme }: StyleFunctionProps) => {
      const isWhiteAlpha = colorScheme === 'whiteAlpha';
      const bgStart = 'transparent';
      const bgHover = `${colorScheme}.50`;
      const bgActive = `${colorScheme}.100`;
      const textColor = isWhiteAlpha ? `${colorScheme}.900` : `${colorScheme}.800`;
      return {
        bg: bgStart,
        color: textColor,
        border: '0.125rem solid',
        borderColor: isWhiteAlpha ? `${colorScheme}.900` : `${colorScheme}.800`,
        _hover: {
          bg: bgHover,
          borderColor: isWhiteAlpha ? `${colorScheme}.900` : `${colorScheme}.800`,
          _disabled: { bg: bgStart },
        },
        _active: { bg: bgActive },
      };
    },
    solid: ({ colorScheme }: StyleFunctionProps) => {
      const isSubtle = colorScheme === 'gray';
      const isContrast = colorScheme === 'whiteAlpha' || colorScheme === 'accent';
      const bgStart = isSubtle ? `${colorScheme}.100` : `${colorScheme}.500`;
      const bgHover = isSubtle ? `${colorScheme}.200` : `${colorScheme}.600`;
      const bgActive = isSubtle ? `${colorScheme}.300` : `${colorScheme}.700`;
      const textColor = isSubtle ? `${colorScheme}.800` : isContrast ? 'black' : 'white';
      return {
        bg: bgStart,
        color: textColor,
        _hover: {
          bg: bgHover,
          _disabled: { bg: bgStart },
        },
        _active: { bg: bgActive },
      };
    },
    unstyled: {
      bg: 'none',
      color: 'inherit',
      display: 'inline',
      lineHeight: 'inherit',
      m: 0,
      p: 0,
    },
  },
  sizes: {
    lg: () => {
      return {
        h: 12,
        minW: 12,
        fontSize: 'lg',
        px: 6,
        svg: { w: 6, h: 6 },
      };
    },
    md: () => {
      return {
        h: 10,
        minW: 10,
        fontSize: 'md',
        px: 4,
        svg: { w: 5, h: 5 },
      };
    },
    sm: () => {
      return {
        h: 8,
        minW: 8,
        px: 3,
        fontSize: 'sm',
        svg: { w: 4, h: 4 },
      };
    },
    xs: () => {
      return {
        h: 6,
        minW: 6,
        px: 2,
        fontSize: 'xs',
        svg: { w: 3, h: 3 },
      };
    },
  },
  baseStyle: {
    lineHeight: '1.2',
    borderRadius: 'md',
    fontWeight: 700,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    textDecoration: 'none',
    _focusVisible: {
      outline: '0.125rem solid',
      outlineColor: 'gray.800',
      outlineOffset: '0.125rem',
    },
    _disabled: { opacity: 0.4, cursor: 'not-allowed', boxShadow: 'none' },
    _hover: { textDecoration: 'none', _disabled: { bg: 'initial' } },
  },
};
