export const headingTheme = {
  baseStyle: {
    fontWeight: 700,
    lineHeight: 'shorter',
  },
  sizes: {},
  variants: {
    AppTitle: { fontSize: 'md' },
  },
  defaultProps: {},
};
