import React, { Component as ReactComponent } from 'react';

import type { ComponentMetaData } from './descriptions';
import type { Interaction, RuntimeInteraction } from './interactions';
import { isReactor, parseComponentType, removeIds } from './reactor';
import {
  META,
  appendPrototype,
  defaults,
  getReactorStoredProperty,
  updatePrototypeChain,
} from './reactorObject';
import { extendDeep } from './util';
import {
  ComponentDescription,
  IDisposable,
  Point,
  Project,
  Properties,
  Reactor,
  ReactorObject,
  useOnComponentUpdate,
} from '.';

// Use Symbols to hide private Component properties we don't want to confuse anyone with.
export const DESCRIPTION = Symbol('DESCRIPTION');
export const MASTER = Symbol('MASTER');
export const INITIAL_STATE = Symbol('INITIAL_STATE');

// Symbolic Object ids.
export const selfObjectId = -1;
export const parentObjectId = -2;
export const pageObjectId = -3;

// These props are expected by all Components' React renderers.
export type ComponentProps = {
  component: Component;
};

export type Effect = Component;

export type ComponentProperties = {
  // TODO: these become reserved property names
  componentType: string;
  _meta?: ComponentMetaData;
  project: Project & { log(...args: any[]): void };
  componentDesignMode?: boolean;
  _componentDesignModeInteractionPoint?: Point;
  _componentDesignModeRootViewInteractionPoint?: Point;

  interactions?: Interaction[];
  effects?: Effect[];

  _runtimeInteractions?: RuntimeInteraction[];
  _metaListeners?: IDisposable[];

  [DESCRIPTION]: ComponentDescription;
  [MASTER]: Component | ComponentDescription | undefined;
  [META]: ComponentMetaData;
  [INITIAL_STATE]: Properties | undefined;
};

export type Component = ReactorObject &
  ComponentProperties & {
    // init context:
    // - called before mount
    // - called before parent's init
    // - called after local state is set
    // - no initialization order guarantees WRT to siblings
    // Should only reference its own state.
    init(): void;

    // Resolve symbolic refereces like 'self', 'parent', 'page' as well as any ReactorId like getComponentById does.
    resolveObjectReference(objectId: number): Component | undefined;
  };

export function isComponent(obj: any): obj is Component {
  return typeof obj === 'object' && DESCRIPTION in obj;
}

export function getDefaultMetaData(): ComponentMetaData {
  return {
    exported: false,
    commands: {},
    events: {},
    properties: {},
  };
}

// Attach listeners to detect metadata changes
function attachMetaListeners(
  target: Component,
  source: Component | ComponentDescription,
): IDisposable[] {
  const ret: IDisposable[] = [];

  if (!isReactor(source)) {
    return ret;
  }

  // Watch for a change to source._meta
  ret.push(
    source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
      property = property as string;

      if (property === '$_meta') {
        // Defer promoteToComponent to the next tick to avoid recursing since property changes are emitted immediately.
        setTimeout(() => promoteToComponent(target, target.project));
      }
    }),
  );

  return ret;
}

// Attach listeners to detect property changes
function attachPropertyListeners(
  target: Component,
  source: Component | ComponentDescription,
): IDisposable[] {
  const ret: IDisposable[] = [];

  if (isReactor(source)) {
    ret.push(
      // Handle changes to the source component
      source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
        inheritProperties(target, source);
      }),
    );
  }

  ret.push(
    // Handle cases where the target component has a prop set to undefined
    target.onPropertyChange((reactor, property, newValue, oldValue, type) => {
      // Only trigger when a prop is being cleared
      if (newValue === undefined) {
        inheritProperties(target, source);
      }
    }),
  );

  return ret;
}

export function initComponent(reactor: Reactor, project: Project): void {
  // Hook Component up with its ComponentDescription.
  promoteToComponent(reactor, project);

  // Now that all initial values, methods, inheritance, etc are set let the Component
  // further initialize itself (unless Project loading is going to do it).
  reactor.init?.();

  // Container.init (at least) creates a new stored property that needs to be evaluated
  // so do this after calling init.
  reactor.evaluateStoredProperties?.();

  reactor.invalidate?.();
}

export function promoteToComponent(reactor: Reactor, project: Project): void {
  const componentType = reactor.componentType;
  const component = reactor as unknown as Component;

  // When processing $_meta, $componentType triggers calls to promoteToComponent
  // Remove componentType from $_meta.properties
  if (typeof componentType !== 'string') {
    return;
  }

  //console.log(`promoteToComponent: ${reactor.name} ${componentType}`);
  //console.log('  component:', component);

  let master = resolveComponentType(componentType, project);
  if (!master) {
    //console.log(`No master for componentType ${componentType} (substituting Play Kit/Orphan)`);
    master = resolveComponentType('Play Kit/Orphan', project)!;
  }
  component[MASTER] = master;

  //console.log('  master:', master);

  // Attach the ComponentDescription to the Reactor -- now it is a Component!
  const description = getComponentDescription(master, project);
  if (!description) {
    console.warn(`No ComponentDescription for componentType ${componentType}`);
    return;
  }
  component[DESCRIPTION] = description;
  //console.log('  description:', description);

  // Build up META
  const meta = getDefaultMetaData();
  extendDeep(meta, component[getReactorStoredProperty]('_meta'), master._meta, description._meta);
  removeIds(meta);

  // Hide private props for instances of custom components
  if ('componentType' in master) {
    for (const name in meta.properties) {
      const prop = meta.properties[name];
      if (prop.private) {
        prop.hidden = true;
      }
    }
  }
  // Update META

  component[META] = meta;

  //console.log('  META: ', component[META]);

  if (component._metaListeners) {
    component._metaListeners.forEach((o) => o.dispose());
  }
  component._metaListeners = [
    ...attachMetaListeners(component, component),
    ...attachMetaListeners(component, master),
    ...attachPropertyListeners(component, master),
  ];

  inheritProperties(component, master);
}

// Return a ComponentDescription for a given component
function getComponentDescription(
  component: Component | ComponentDescription,
  project: Project,
): ComponentDescription | undefined {
  while (isReactor(component)) {
    if (isComponent(component)) {
      // the component is already a Component, so just use its ComponentDescription
      return component[DESCRIPTION];
    } else {
      // Walk up the inheritance chain
      component = resolveComponentType((component as Component).componentType, project)!;
      if (!component) {
        return undefined;
      }
    }
  }
  // the component is already JS ComponentDescription
  return component as ComponentDescription;
}

export function getComponentProperties(component: Component) {
  const props: Properties = {};
  const propertyDescriptions = component[META].properties;

  if (!propertyDescriptions) {
    return {};
  }

  for (const key in propertyDescriptions) {
    const description = propertyDescriptions[key];

    if (description === undefined) {
      continue;
    }

    const value = component[key] ?? description.default;
    const { type: propType } = description;

    if (typeof value === 'function') {
      continue;
    } else if (propType === 'object') {
      if (isComponent(value)) {
        props[key] = getComponentProperties(value);
      } else {
        props[key] = convertToPlainObject(value);
      }
    } else if (propType === 'array') {
      props[key] = [];
      for (const el of value) {
        if (isComponent(el)) {
          props[key].push(getComponentProperties(el));
        } else {
          props[key].push(convertToPlainObject(el));
        }
      }
    } else {
      props[key] = value;
    }
  }

  return props;

  function convertToPlainObject(ob: any): Properties {
    const props: Properties = {};

    for (const key in ob) {
      const value = ob[key];

      if (typeof value !== 'function') {
        props[key] = value;
      }
    }

    return props;
  }
}

// Merge inherited properties from a master (optional) and per-property defaults into a component
function inheritProperties(component: Component, master: Component | ComponentDescription): void {
  const defProps: Properties = {};
  const meta = component[META];
  for (const property in meta.properties) {
    const defaultValue = meta.properties[property].default;
    if (master.hasOwnProperty(property)) {
      // Only inherit if the property is actually defined on the master
      defProps[property] = (master as any)[property];
    } else if (defaultValue !== undefined) {
      // Only inherit if the default value is set
      defProps[property] = defaultValue;
    }
  }

  // Save the default properties as an object that will be added to the component's prototype chain
  // (by ReactorObject.updatePrototypeChain). We can then use OwnProperties to tell when a property
  // has been overridden from its default, even if by the same value.
  component[defaults] = defProps;

  // Some default properties, like style, are objects which won't behave as desired if inherited.
  // The default value will be changed instead of an instance value.
  // Therefore, we apply object values directly to the component instance.
  for (const property in defProps) {
    if (typeof defProps[property] === 'object') {
      // inheritProperties is called at different times during a component instance's life.
      // Don't overwrite existing properties.
      if (component[property] === undefined) {
        component[property] = defProps[property];
      }
    }
  }

  // Put the new defaults object on the component's prototype chain.
  component[updatePrototypeChain]();

  // TODO: The prior implementation set the default property values on the instance itself
  // which sent property change notifications. We should probably send change notifications here.

  function annotateEffectInteractions(effect: Effect): RuntimeInteraction[] {
    return effect._runtimeInteractions?.map((interaction) => ({ ...interaction, effect })) ?? [];
  }

  // Merge interactions into _runtimeInteractions
  // Order:
  //   1. master effect interactions
  //   2. master interactions
  //   3. local effect interactions
  //   4. local interactions
  const interactions = [
    ...((master as Component)?.effects?.flatMap(annotateEffectInteractions) ?? []),
    ...(master?.interactions ?? []),
    ...(component.effects?.flatMap(annotateEffectInteractions) ?? []),
    ...(component.interactions ?? []),
  ];
  component._runtimeInteractions = interactions;
}

export const ComponentBridge: React.FC<{
  component: Component;
}> = ({ component }) => {
  const componentState = useOnComponentUpdate<Component>(component, getRenderProperties(component));
  component.project.log(`ComponentBridge render ${component.name || component.id}`);

  const description = component[DESCRIPTION];
  const Renderer = description?.renderer;

  if (Renderer) {
    return (
      <ErrorBoundary component={component}>
        <Renderer component={component} {...componentState} />
      </ErrorBoundary>
    );
  } else {
    return null;
    //return <div>{component.componentType} has no renderer</div>;
  }
};

function getRenderProperties(component: Component): PropertyKey[] {
  const propertyKeys = Object.keys(component?.[META]?.properties || {});
  return propertyKeys.filter((k) => !component?.[META]?.properties?.[k]?.noRender);
}

export function getDescription(component: Component): ComponentDescription | undefined {
  return component[DESCRIPTION];
}

// Follow an import path through a projects import hierarchy
function resolveImport(importPath: string, root: Project): Reactor | undefined {
  const path = importPath.split('/');
  for (const name of path) {
    root = root.imports?.[name];
    if (!root) {
      break;
    }
  }

  return root;
}

// Return the ComponentMaster or ComponentDescription for a componentType
export function resolveComponentType(
  componentType: string,
  project: Project,
): Component | ComponentDescription | undefined {
  let root = project as Reactor | undefined;
  const { importPath, unqualifiedType } = parseComponentType(componentType);
  if (importPath) {
    // Component is referencing an imported project
    root = resolveImport(importPath, project);
    if (!root) {
      // console.warn(`Project has no ${importPath} import for "${componentType}".`);
      return undefined;
    }
  }

  if (isReactor(root)) {
    // This is a Component master
    const master = root!.Components?.[unqualifiedType] as Component | undefined;
    if (!master) {
      // console.warn(`Component "${unqualifiedType}" doesn't exist. importPath:${importPath}.`);
      return undefined;
    }
    return master;
  } else {
    // This is a JS module, import the Description directly
    let description = root![unqualifiedType + 'Description'] as ComponentDescription | undefined;
    if (!description) {
      // console.warn(`Module ${importPath} does not export ${unqualifiedType}Description`);
      return undefined;
    }
    description = buildComponentDescription(description, project);
    return description;
  }
}

// Return a ComponentDescription with protype/extends processing
function buildComponentDescription(
  description: ComponentDescription,
  project: Project,
): ComponentDescription | undefined {
  // Don't alter the original.
  description = {
    ...description!,
  };

  // If the description "extends" another perform a manual inheritance.
  // Also treate componentType the same
  if (description.extends) {
    const protoDescription = resolveComponentType(description.extends, project);
    if (protoDescription && !isReactor(protoDescription)) {
      // TODO: Do we really want ALL properties inherited?
      description = { ...protoDescription, ...description };

      // Inherit the protoDescription's prototype.
      if (
        description.prototype &&
        description.prototype !== protoDescription?.prototype &&
        protoDescription?.prototype
      ) {
        appendPrototype(description.prototype, protoDescription.prototype);
      }

      // Extend the metadata too
      extendDeep(description._meta!, protoDescription._meta);
    } else {
      console.error(
        `Invalid Component Description for ${description.extends} while processing ${description.name}`,
      );
    }
  }

  return description as ComponentDescription;
}

// Find, in the current project, the component at the specified path.
// Return undefined if it none exists.
// TODO: scope?
export function getComponentByPath(path: string | undefined): Properties | undefined {
  if (path === undefined) {
    return undefined;
  }

  // TODO:
  return undefined;
}

export function isCustomComponentInstance(component: Component): boolean {
  if (!component.componentType) {
    return false;
  }
  return isReactor(component[MASTER]);
}

export function eventNameFromHandlerName(handlerName: string): string {
  return lowerFirstLetter(handlerName.slice(2));
}

function lowerFirstLetter(s: string): string {
  return s[0].toLowerCase() + s.slice(1);
}

export class ErrorBoundary extends ReactComponent<
  { component: Component },
  { message?: string; stack?: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { message: error.message, stack: error.stack };
  }

  componentDidCatch(error: any, errorInfo: any) {
    //this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.message) {
      const { width, height } = this.props.component;
      return (
        <div
          style={{
            width,
            height,
            color: 'darkred',
            backgroundColor: '#ffc0c0',
            position: 'absolute',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            fontSize: '13px',
          }}
        >
          {this.state.stack}
        </div>
      );
    }

    return this.props.children;
  }
}
