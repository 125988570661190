import type { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleFunction<typeof parts> = ({ scrollBehavior, isCentered }) => {
  return {
    overlay: {
      backdropFilter: 'blur(0.25rem) invert(10%)',
    },
    dialog: {
      top: '0',
      bg: 'white',
      my: isCentered ? 'auto' : '3.75rem',
      overflow: scrollBehavior === 'inside' ? 'hidden' : undefined,
    },
    header: { px: 6, pt: 4, pb: 2, fontWeight: 'bold', color: 'gray.700' },
    dialogContainer: {
      alignItems: 'flex-start',
    },
    closeButton: {
      bg: 'transparent',
      w: 10,
      h: 10,
      borderRadius: 'md',
      _hover: { bg: 'gray.50' },
      _focus: { bg: 'gray.50' },
      _focusVisible: {
        outline: '0.125rem solid',
        outlineColor: 'gray.800',
        outlineOffset: '0.125rem',
      },
      _active: { bg: 'gray.100' },
    },
    body: {
      px: 6,
      pb: 2,
      pt: 2,
    },
    footer: {
      pt: 2,
    },
  };
};

export const modalTheme = {
  defaultProps: { size: 'md' },
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle,
  variants: {},
  sizes: {
    xs: { dialog: { maxW: 'xs' } },
    sm: { dialog: { maxW: 'sm' } },
    md: { dialog: { maxW: 'md' } },
    lg: { dialog: { maxW: 'lg' } },
    xl: { dialog: { maxW: 'xl' } },
    full: {
      dialog: {
        borderRadius: 0,
        mx: 0,
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': { minH: '-webkit-fill-available' },
        my: 0,
      },
    },
  },
};
