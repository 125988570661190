export const SESSION_RECORDING_CONSENT = 'sessionRecording';

/**
 * Add any [key: string]: boolean value here to create a new flag.
 * Adding a new flag here will automatically add a menu item in the user menu
 * which allows a user to toggle the flag.
 */

export const FEATURE_UNDER_CONSTRUCTION = 'underConstruction';
export const FEATURE_DEVELOPER_MODE = 'developerMode';
export const FEATURE_INTERNAL_TOOLS = 'internalTools';

export const ACHIEVEMENT_FIRST_EFFECT = 'achievementFirstEffect';

export const TUTORIAL_ONBOARDING = 'tutorialOnboarding';

export const InitialUserFeatures = {
  [FEATURE_UNDER_CONSTRUCTION]: false,
  [FEATURE_DEVELOPER_MODE]: false,
  [FEATURE_INTERNAL_TOOLS]: false,
};

export const InitialUserAchievements = {
  [ACHIEVEMENT_FIRST_EFFECT]: false,
};

export const InitialUserFlags = {
  [SESSION_RECORDING_CONSENT]: false,
  [TUTORIAL_ONBOARDING]: false,
};

export type UserFlags = typeof InitialUserFeatures &
  typeof InitialUserFlags &
  typeof InitialUserAchievements;

export const userFlagDefaults = {
  ...InitialUserFeatures,
  ...InitialUserAchievements,
  ...InitialUserFlags,
};

// User auth token claims
export const InitialUserClaims = {
  admin: false,
};

export type UserClaims = typeof InitialUserClaims;
