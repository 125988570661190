import { SliderTrack as ChSliderTrack } from '@chakra-ui/slider';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';

import type { SliderTrackProps } from './types';

export { Slider, SliderFilledTrack, SliderMark, SliderThumb } from '@chakra-ui/slider';

export const SliderTrack = forwardRef((props: SliderTrackProps, ref) => {
  const { children, bg, bgGradient, ...restProps } = props;
  return (
    <ChSliderTrack
      ref={ref}
      _before={{
        position: 'absolute',
        content: '""',
        top: 0,
        bottom: 0,
        borderRadius: 'full',
        bg: bgGradient ? '' : bg || 'gray.100',
        bgGradient,
        ...restProps,
      }}
    >
      {children}
    </ChSliderTrack>
  );
});
